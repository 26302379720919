@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  display: flex;
  padding: 0px 32px 0 0px;
  height: 100%;
}

.page {
  width: calc(100% - var(--menubar-expanded-width) - var(--margin));
  margin-left: var(--margin);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  @include tablet {
    width: calc(100% - var(--menubar-collapsed-width) - var(--margin));
    height: calc(100vh - #{$menubar-height});
  }
}

.search {
  margin: 10px 0 0 0;
}

.athleteCardWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(262px, 1fr));
  width: 100%;
  margin-top: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #f3f3f6;
}
