@import "src/styles/mixins";
@import "src/styles/variables";

.athleteSelectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin: 16px 0 16px 0;
  border-bottom: 1px solid $card-color;

  @include tablet {
    margin-bottom: 10px;
  }
}

.athleteLeft {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 1.5rem;
}

.athleteText {
  font-size: 2rem;
  font-weight: 600;
  color: $primary-text-color;

  &::after {
    content: "\2022";
    font-size: 2rem;
    font-weight: 600;
    color: $secondary-text-color;
    margin-left: 20px;
  }
}

.athleteCount {
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  color: $accent-primary;
}

.search {
  display: none;
}
