.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.small {
  transform: scale(0.7);
}

.bar {
  transform: scale(0.7);
  top: -20px;
}

.static {
  position: static;
  flex: 1;
}

.caption {
  width: 130px;
  max-width: 100%;
  margin-top: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
