.analog-textarea {
  position: relative;
}

.analog-textarea__textarea {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  min-height: 64px;
  border-radius: 8px;
  outline: none;
  color: var(--analog-text-primary);
  padding: 8px 12px;
  resize: vertical;
  font-family: inherit;
  font-weight: 400;
}

.analog-textarea--light .analog-textarea__textarea {
  background-color: var(--analog-text-field-background2);
  border: 1px solid var(--analog-text-field-border2);
}

.analog-textarea--dark .analog-textarea__textarea {
  background-color: var(--analog-surface-layer2);
  border: 1px solid var(--analog-surface-layer2);
}

.analog-textarea--light .analog-textarea__textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--analog-text-field-background2) inset;
  -webkit-text-fill-color: var(--analog-text-primary);
}

.analog-textarea--dark .analog-textarea__textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--analog-surface-layer2) inset;
  -webkit-text-fill-color: var(--analog-text-primary);
}

.analog-textarea--autosize .analog-textarea__textarea {
  resize: none;
  overflow: hidden;
  field-sizing: content;
}

.analog-textarea__textarea::placeholder {
  color: var(--analog-text-placeholder);
}

.analog-textarea--invalid .analog-textarea__textarea:hover,
.analog-textarea__textarea:hover {
  border-color: var(--analog-border-primary-inverse);
}

.analog-textarea--invalid .analog-textarea__textarea:active,
.analog-textarea--invalid .analog-textarea__textarea:focus-visible,
.analog-textarea__textarea:active,
.analog-textarea__textarea:focus-visible {
  border-color: var(--analog-active-primary);
}

.analog-textarea__length {
  position: absolute;
  right: 0;
  bottom: -22px;
}

.analog-textarea--invalid .analog-textarea__textarea {
  border-color: var(--analog-support-error);
}

.analog-textarea--disabled {
  pointer-events: none;
  user-select: none;
}

.analog-textarea--disabled .analog-textarea__textarea {
  background-color: var(--analog-text-field-disabled2);
  border-color: var(--analog-disabled1);
  color: var(--analog-disabled1);
  resize: none;
}
