@import "src/styles/mixins";

.wrapper {
  list-style: none;
  margin: 5px 0;
  border-bottom: 1px solid rgba($accent-primary, 0.2);
  &:last-child {
    border-bottom: none;
  }
}

.button {
  @include reset-button;
  padding: 9px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: $dropdown-bg;
}

.icon {
  flex: 1;
}

.text {
  flex: 2;
  margin-left: 6px;
  color: $primary-text-color;
  font-size: 14px;
  line-height: 17px;
}
