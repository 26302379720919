@import "src/styles/mixins";

.wrapper {
  margin-left: 0;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.athleteHeader {
  display: flex;
  align-items: center;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $athlete-avatar-border-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.info {
  margin-left: 16px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.strong {
  font-weight: 600;
}

.age {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.country {
  display: flex;
  align-items: center;
  height: 25px;
  margin-left: 20px;
  border-radius: 5px;
}
