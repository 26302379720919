@import "src/styles/variables";
@import "src/styles/mixins";

.popupBox {
  position: fixed;
  z-index: 20;
  background: rgba($pop-up-bg-color, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  position: relative;
  margin: 0 auto;
  height: auto;
  background: $popup-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: auto;
  width: 576px;
  max-width: 100%;
  padding: 18px 18px 30px 18px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.closeButton {
  cursor: pointer;
  margin-left: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-top: 21px;
  text-align: center;
}

.subtitle {
  margin: 10px 20px 0;
  font-size: 14px;
  line-height: 20px;
  color: $primary-text-color;
  text-align: center;
}

.description {
  margin: 2px 0 28px;
  font-size: 14px;
  line-height: 20px;
  color: $secondary-text-color;
  text-align: center;
}

.activeAddButton {
  background: $account-button-color;
  pointer-events: all;
  border: none;
  padding: 0 50px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 60px;
  text-align: center;
  color: #fff;
}
