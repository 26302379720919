@import "src/styles/variables";
@import "src/styles/mixins";

.athleteGroup {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.backNav {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 10px;
  height: 40px;
  width: 40px;
  background-color: $card-color;
  border-radius: 5px;
  cursor: pointer;
  fill: $icon-color;
}

.headerTitle {
  font-size: 25px;
  font-weight: 700;
}
