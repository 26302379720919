.analog-table {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  min-height: 0;
  background-color: var(--analog-surface-layer1);
  border: 1px solid var(--analog-border-primary);
  border-radius: 8px;
}

.analog-table__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  overflow: auto;
}

.analog-table__table {
  width: 100%;
}

.analog-table__tableBody:not(.analog-table__tableBody--loading)
  .analog-table__row:hover
  .analog-table__cell {
  background-color: var(--analog-surface-layer2);
}

.analog-table__row {
  display: flex;
  flex-grow: 1;
}

.analog-table__tableHead {
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: default;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.analog-table__tableHead .analog-table__cell {
  height: 56px;
  background: var(--analog-surface-layer3);
}

.analog-table__cell {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 16px;
  vertical-align: middle;
  color: var(--analog-text-primary);
  white-space: nowrap;
  height: 52px;
  flex-basis: 100%;
}

.analog-table__cell--left {
  text-align: left;
  justify-content: flex-start;
}

.analog-table__cell--center {
  text-align: center;
  justify-content: center;
}

.analog-table__cell--right {
  text-align: right;
  justify-content: flex-end;
}

.analog-table__pagination {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: default;
  border-top: 1px solid var(--analog-border-primary);
}

.analog-table__pagination .analog-pagination {
  width: 100%;
}

.analog-table__pagination-actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
}

.analog-table__pagination-selected {
  user-select: none;
  color: var(--analog-text-primary);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;
}

.analog-table__pagination-selected span {
  color: var(--analog-text-primary);
}

.analog-table__headText {
  user-select: none;
  display: inline-flex;
  vertical-align: middle;
}

.analog-table__headText--withAdornment {
  margin-inline-end: 8px;
}

.analog-table__cellHead--clickable {
  cursor: pointer;
}

.analog-table__headTooltip {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

.analog-table__headTooltip--withSort {
  margin-inline-end: 8px;
}

.analog-table__headSortIcon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  color: var(--analog-text-primary);
  display: inline-flex;
  vertical-align: middle;
  margin-inline-start: auto;
}

.analog-table__headSortIcon .analog-button--icon.analog-button--xxs {
  width: 18px;
  height: 18px;
}

.analog-table__emptyState {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
}
