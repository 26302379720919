@import "src/styles/variables";
@import "src/styles/mixins";

// ToDo: lines 4-17 from session-expired-popup.module.scss - duplicates. Need to refactor.
.popupBox {
  position: fixed;
  z-index: 200;
  background: rgba($pop-up-bg-color, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  background: $popup-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.inputBox {
  position: relative;
  width: 35vw;
  padding: 32px;
  height: 80vh;

  @include tablet {
    width: 52vw;
  }

  @include tablet-portrait {
    width: 72vw;
  }
}

.athleteCardWrapper {
  margin-top: 10px;
  display: flex;
  height: 80%;
  flex-direction: column;
  gap: 1rem;
}

.athleteSearch {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &span {
    font-size: 14px;
    font-weight: 600;
  }
}

.searchBar {
  width: 100%;
}

.listWrapper {
  overflow: auto;
  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item {
  height: 40px;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  background-color: transparent;
  border: 0px solid $hover-color;
}

.itemDisabled {
  opacity: 0.4;
}

.itemImage {
  width: 34px;
  border-radius: 10%;
}

.closeButton {
  cursor: pointer;
  margin-left: auto;
  fill: $accent-primary;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  margin-left: 0px;
  width: 128px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 600;
}

.clearSelection {
  background-color: transparent;
  color: $clear-all-color;
  margin-left: 0px;
  width: 128px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 600;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba($hover-color, 0.5);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
