.analog-dropdown {
  display: inline-flex;
}

.analog-dropdown__anchor {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
}

.analog-dropdown__content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--analog-surface-layer2);
  border: 1px solid var(--analog-border-middle);
  z-index: var(--layer-overlay);
  outline: none;
  overflow: hidden;
}
