.analog-truncated-tags {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.analog-truncated-tags__valueHiddenTags {
  position: relative;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.analog-truncated-tags__toggle-valueTags .analog-tag__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.analog-truncated-tags__tag-wrapper {
  display: inline-flex;
  margin-right: 4px;
}

.analog-truncated-tags__tag-wrapper:last-of-type {
  margin-right: 0;
}

.analog-truncated-tags__tag-wrapper--hidden {
  opacity: 0;
  visibility: hidden;
  margin-right: 0;
  position: absolute;
}

.analog-truncated-tags__tagCount {
  display: inline-flex;
}
