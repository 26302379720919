@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  display: flex;
  padding: 0px 32px 0 0px;
  height: 100%;
}

.page {
  width: calc(100% - var(--menubar-expanded-width) - var(--margin));
  margin-left: var(--margin);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease; // add transition for smooth resize

  @include tablet {
    width: calc(100% - var(--menubar-collapsed-width) - var(--margin));
    height: calc(100% - #{$menubar-height});
  }
}

.heading {
  margin: 10px 0;
}

.athleteCardWrapper {
  width: 100%;
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: $athlete-card-color;
  background: $card-color;
  border-radius: 12px;
  padding: 24px;
}

.headerBlock {
  display: flex;
  align-items: center;
  height: 72px;
}

.header {
  font-size: 42px;
  line-height: 48px;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin: 0;

  @include tablet {
    font-size: 32px;
  }
}

.description {
  font-size: 20px;
  line-height: 46px;
  font-weight: 300;
  letter-spacing: 0.02em;
}

.form {
  width: 100%;
  margin-top: 50px;
  margin: auto;
}

.inputsBlock {
  display: flex;
  width: 100%;
  gap: 32px;
  justify-content: space-between;
  flex-direction: row;
  @include tablet-portrait {
    flex-direction: column;
    gap: 24px;
  }
}

.inputBlock {
  width: 100%;
}

.label {
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: $sign-in-input-label-color;
}

.inputWrapper {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  position: relative;
}

.passwordInput {
  position: absolute;
  outline: none;
  display: block;
  width: 100%;
  height: 100%;
  background: $sign-in-textfield-bg-color;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  padding: 18px 45px 18px 60px;
  color: $accent-primary;
  border: 1px solid transparent;
  z-index: 5;
}

.passwordInput::placeholder {
  color: $sign-in-textfield-placeholder;
}

.passwordInput:focus {
  border: 1px solid $sign-in-textfield-border-color;
}

.iconWrapper {
  @include reset-button;
  position: absolute;
  border: none;
  z-index: 7;
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: $sign-in-textfield-placeholder;
  }
}

.iconWrapper:focus {
  outline: 1px solid #4f576d;
}

.activeIcon {
  svg {
    fill: $accent-primary;
  }
}

.passwordEyeIcon {
  right: 0;
}

.warningIcon {
  right: 0;
}

.errorInput {
  border: 2px solid $sign-in-error-color;
}

.buttonBlock {
  margin-top: 24px;
  position: relative;
  z-index: 5;
  text-align: right;
}

.button {
  @include reset-button;
  margin-left: 16px;
  padding: 10px 20px;
  border: 1px solid $accent-primary;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $accent-primary;
  background: $account-btn-bg;
}

.button:disabled {
  color: #454c61;
  background: $account-default-btn-color;
  box-shadow: none;
}

.errorMessage {
  margin-top: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $accent-secondary;
}

.passwordMessage {
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $sign-in-text-color;
}

.successMessage {
  margin-top: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $accent-primary;
}

.backButton {
  @include reset-button;
  position: absolute;
  top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: 5px;
  }
  @include tablet-portrait {
    top: 13px;
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid $account-button-color;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
