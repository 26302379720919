.headerTitle {
  color: var(--analog-text-primary);
}

.basicStats {
  margin-block-start: 2px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.basicStatsItem {
  color: var(--analog-text-secondary);
}

.basicStatsDivider {
  width: 1px;
  height: 12px;
  background: #2e3748;
}
