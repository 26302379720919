@import "src/styles/mixins";

.heading {
  margin-bottom: 1rem;
}

.container {
  border-radius: 8px;
  background-color: $panel-color;
  border: 1px solid $border-color;
}

.cards {
  display: grid;
  padding: 10px;
  grid-template-columns: 22rem 17.5rem minmax(0, 1fr);
  grid-auto-rows: 27.5rem;
  grid-gap: 1rem;

  @include mq(portrait) {
    grid-gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr) 17.5rem;

    :nth-child(3) {
      grid-column: span 2;
    }
  }
}

.source {
  margin: 0;
}
