.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  background: rgba(19, 19, 21, 0.5);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgb(27, 27, 27);
  color: rgb(255, 59, 48);
  z-index: 1200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin: 0 0 5px;
    text-transform: uppercase;
    font-size: 14px;
  }
}

.select {
  background-color: rgb(19, 19, 21);
  color: white;
  border: 1px solid rgb(48, 48, 48);
  border-radius: 6px;
  display: inline-block;
  font-size: 9px;
  padding: 0 10px;
  width: 100px;
  height: 27px;
  text-transform: uppercase;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select {
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, rgb(19, 19, 21), rgb(19, 19, 21));
  background-position:
    calc(100% - 20px) 52%,
    calc(100% - 15px) 52%,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

.select:focus {
  border: 1px solid rgb(255, 59, 48);
}

.select:focus {
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, rgb(19, 19, 21), rgb(19, 19, 21));
  outline: 0;
}

.bodies {
  max-width: 600px;
  margin: 0 auto;
  // padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  // not required for now
  p {
    color: rgb(48, 48, 48);
    text-transform: uppercase;
    font-size: 13px;
    text-align: center;
  }
}

// .anteriorMap {
//     margin: 1rem;
// }

@media (max-width: 500px) {
  .bodies {
    flex-direction: column;
  }
}
