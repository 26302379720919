// $############### all colors #################
$primary-gray-25: #fcfcfd;
$primary-gray-50: #f9fafb;
$primary-gray-100: #f2f4f7;
$primary-gray-200: #e4e7ec;
$primary-gray-200-rgb: red($primary-gray-200), green($primary-gray-200),
  blue($primary-gray-200);
$primary-gray-300: #d0d5dd;
$primary-gray-400: #98a2b3;
$primary-gray-500: #667085;
$primary-gray-600: #475467;
$primary-gray-700: #344054;
$primary-gray-800: #1d2939;
$primary-gray-900: #101828;

$primary-green-25: #f6fef9;
$primary-green-50: #ecfdf3;
$primary-green-100: #d1fadf;
$primary-green-200: #a6f4c5;
$primary-green-300: #6ce9a6;
$primary-green-400: #32d583;
$primary-green-500: #12b76a;
$primary-green-600: #039855;
$primary-green-700: #027a48;
$primary-green-800: #05603a;
$primary-green-900: #054f31;

$primary-orange-25: #fffcf5;
$primary-orange-50: #fffaeb;
$primary-orange-100: #fef0c7;
$primary-orange-200: #fedf89;
$primary-orange-300: #fec84b;
$primary-orange-400: #fdb022;
$primary-orange-500: #f79009;
$primary-orange-600: #dc6803;
$primary-orange-700: #b54708;
$primary-orange-800: #93370d;
$primary-orange-900: #7a2e0e;

$primary-purple-25: #fcfaff;
$primary-purple-50: #f9f5ff;
$primary-purple-100: #f4ebff;
$primary-purple-200: #e9d7fe;
$primary-purple-300: #d6bbfb;
$primary-purple-400: #b692f6;
$primary-purple-500: #9e77ed;
$primary-purple-600: #7f56d9;
$primary-purple-700: #6941c6;
$primary-purple-800: #53389e;
$primary-purple-900: #42307d;

$primary-red-25: #fffbfa;
$primary-red-50: #fef3f2;
$primary-red-100: #fee4e2;
$primary-red-200: #fecdca;
$primary-red-300: #fda29b;
$primary-red-400: #f97066;
$primary-red-500: #f04438;
$primary-red-600: #d92d20;
$primary-red-700: #b42318;
$primary-red-800: #912018;
$primary-red-900: #7a271a;

$secondary-blue-25: #f5faff;
$secondary-blue-50: #eff8ff;
$secondary-blue-100: #d1e9ff;
$secondary-blue-200: #b2ddff;
$secondary-blue-300: #84caff;
$secondary-blue-400: #53b1fd;
$secondary-blue-500: #2e90fa;
$secondary-blue-500-rgb: red($secondary-blue-500), green($secondary-blue-500),
  blue($secondary-blue-500);
$secondary-blue-600: #1570ef;
$secondary-blue-700: #175cd3;
$secondary-blue-800: #1849a9;
$secondary-blue-900: #194185;

$secondary-blue-gray-25: #fcfcfd;
$secondary-blue-gray-50: #f8f9fc;
$secondary-blue-gray-100: #eaecf5;
$secondary-blue-gray-200: #c8cce5;
$secondary-blue-gray-300: #9ea5d1;
$secondary-blue-gray-400: #717bbc;
$secondary-blue-gray-500: #4e5ba6;
$secondary-blue-gray-600: #3e4784;
$secondary-blue-gray-700: #363f72;
$secondary-blue-gray-800: #293056;
$secondary-blue-gray-900: #101323;

$secondary-blue-light-25: #f5fbff;
$secondary-blue-light-50: #f0f9ff;
$secondary-blue-light-100: #e0f2fe;
$secondary-blue-light-200: #b9e6fe;
$secondary-blue-light-300: #7cd4fd;
$secondary-blue-light-400: #36bffa;
$secondary-blue-light-500: #0ba5ec;
$secondary-blue-light-600: #0086c9;
$secondary-blue-light-700: #026aa2;
$secondary-blue-light-800: #065986;
$secondary-blue-light-900: #0b4a6f;

$secondary-indigo-25: #f5f8ff;
$secondary-indigo-50: #eef4ff;
$secondary-indigo-100: #e0eaff;
$secondary-indigo-200: #c7d7fe;
$secondary-indigo-300: #a4bcfd;
$secondary-indigo-400: #8098f9;
$secondary-indigo-500: #6172f3;
$secondary-indigo-600: #444ce7;
$secondary-indigo-700: #3538cd;
$secondary-indigo-800: #2d31a6;
$secondary-indigo-900: #2d3282;

$secondary-orange-25: #fffaf5;
$secondary-orange-50: #fff6ed;
$secondary-orange-100: #ffead5;
$secondary-orange-200: #fddcab;
$secondary-orange-300: #feb273;
$secondary-orange-400: #fd853a;
$secondary-orange-500: #fb6514;
$secondary-orange-600: #ec4a0a;
$secondary-orange-700: #c4320a;
$secondary-orange-800: #9c2a10;
$secondary-orange-900: #7e2410;

$secondary-pink-25: #fef6fb;
$secondary-pink-50: #fdf2fa;
$secondary-pink-100: #fce7f6;
$secondary-pink-200: #fcceee;
$secondary-pink-300: #faa7e0;
$secondary-pink-400: #f670c7;
$secondary-pink-500: #ee46bc;
$secondary-pink-500-rgb: red($secondary-pink-500), green($secondary-pink-500),
  blue($secondary-pink-500);
$secondary-pink-600: #dd2590;
$secondary-pink-700: #c11574;
$secondary-pink-800: #9e165f;
$secondary-pink-900: #851651;

$secondary-purple-25: #fafaff;
$secondary-purple-50: #f4f3ff;
$secondary-purple-100: #ebe9fe;
$secondary-purple-200: #d9d6fe;
$secondary-purple-300: #bdb4fe;
$secondary-purple-400: #9b8afb;
$secondary-purple-500: #7a5af8;
$secondary-purple-600: #6938ef;
$secondary-purple-700: #5925dc;
$secondary-purple-800: #4a1fb8;
$secondary-purple-900: #3e1c96;

$secondary-rose-25: #fff5f6;
$secondary-rose-50: #fff1f3;
$secondary-rose-100: #ffe4e8;
$secondary-rose-200: #fecdd6;
$secondary-rose-300: #fea3b4;
$secondary-rose-400: #fd6f8e;
$secondary-rose-500: #f63d68;
$secondary-rose-600: #e31b54;
$secondary-rose-700: #c01048;
$secondary-rose-800: #a11043;
$secondary-rose-900: #89123e;

$black: #000;
$white: #fff;
