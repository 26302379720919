@import "src/styles/mixins";
@import "src/styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.total {
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.input {
  height: 12px;
  width: 12px;
  margin-right: 10px;
  fill: $accent-primary;
}

.source {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
