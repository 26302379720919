@import "src/styles/variables";
@import "src/styles/mixins";

.bRGCiY {
  background-color: $card-color !important;
}

.sc-iwXfZk {
  color: $secondary-text-color !important;
}

.dOTSzF:disabled svg {
  color: aliceblue !important;
}

.sc-epnzzT,
.hDPlLz {
  background-color: $card-color !important;
  border-radius: 10px !important;
  margin-bottom: 1rem !important;
}

// pagination control buttons
.sc-bbQqnZ svg {
  fill: $primary-text-color !important;
}

// tableHeading
.iljkDU {
  font-size: 12px !important;
}

// remove border radius
.sc-epnzzT,
.hDPlLz {
  background: none !important;
}
