@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.singleTooltip {
  color: $tooltip-font-color;
}

.tooltip {
  display: flex;
  justify-content: space-between;
}

.tooltipValue {
  margin-left: 5px;
}

.tooltipIcon {
  margin-right: 3px;
}
