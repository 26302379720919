@import "src/styles/variables";
@import "src/styles/mixins";

.inputsBlock {
  display: flex;
  width: 100%;
  gap: 32px;
  justify-content: space-between;
  flex-direction: row;
  @include tablet-portrait {
    flex-direction: column;
    gap: 24px;
  }
}

.inputBlock {
  width: 100%;
}

.iconWrapper {
  @include reset-button;
  position: absolute;
  border: none;
  z-index: 7;
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  svg {
    fill: $sign-in-textfield-placeholder;
  }
}

.label {
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: $sign-in-input-label-color;
}

.inputWrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.passwordInput {
  position: absolute;
  outline: none;
  display: block;
  width: 100%;
  height: 100%;
  background: $sign-in-textfield-bg-color;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  padding: 18px 45px 18px 60px;
  color: $accent-primary;
  border: 1px solid transparent;
  z-index: 5;
}

.passwordInput::placeholder {
  color: $sign-in-textfield-placeholder;
}

.passwordInput:focus {
  border: 1px solid $sign-in-textfield-border-color;
}

.sendWrapper {
  @include reset-button;
  position: absolute;
  border: none;
  background-color: $chat-bubble-system-bg;
  width: 34px;
  padding: 8px;
  border: 8px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 4px;
}

.sendWrapper:disabled {
  cursor: none;
  background-color: $previousDisabledBackgroundColor;
  cursor: not-allowed;
}

.textarea {
  font-family: inherit;
  position: relative;
  resize: none;
  overflow-y: hidden;
  height: auto;
  max-height: 110px; /* Set max height for 5 lines */
  width: 100%;
  background: $chat-textfield-bg;
  padding: 10px;
  border-radius: 6px;
  color: $primary-input-text-color;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.024px;
  padding-right: 45px;
  &::placeholder {
    color: $sign-in-textfield-placeholder;
  }
  &:focus-visible {
    outline: none;
  }

  &.errorInput {
    border: 1px solid red;
    background-color: rgba($error-text, 0.2);
    &::placeholder {
      color: $primary-text-color;
    }
  }
}
