.analog-badge__container {
  position: relative;
  display: inline-block;
}

.analog-badge__badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -25%);
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.analog-badge__badge--primary .analog-badge__badge {
  background-color: var(--analog-interactive-primary);
  color: var(--analog-white);
}

.analog-badge__badge--success .analog-badge__badge {
  background-color: var(--analog-support-success);
  color: var(--analog-white);
}

.analog-badge__badge--warning .analog-badge__badge {
  background-color: var(--analog-support-warning);
  color: var(--analog-white);
}

.analog-badge__badge--error .analog-badge__badge {
  background-color: var(--analog-support-error);
  color: var(--analog-white);
}
