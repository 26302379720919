@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
}
