@import "src/styles/variables";
@import "src/styles/mixins";

.menubar {
  width: var(--menubar-expanded-width);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  background: $menu-color;
  overflow-y: auto;

  @include tablet {
    width: var(--menubar-collapsed-width);
    overflow-y: visible;
  }
}

.logoItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.womenLogoItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.logoTitle {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: $account-default-btn-color;

  @include tablet {
    display: none;
  }
}

.logo {
  height: 50px;
}

.menubarLogoHeader {
  height: 77px;
  background: $menubar-gradient;
  border-top-right-radius: 12px;
  overflow: hidden;
}

.menubarLogoWatermark {
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 15px;
  align-items: center;
  background-image: url("../../images/menu-title-watermark.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @include tablet {
    padding-left: 0px;
    justify-content: center;
  }
}

.menubarWomenLogoWatermark {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/menu-title-watermark.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.dropdownContainer {
  cursor: pointer;
  flex-direction: column;
  border-radius: 6px;
  position: relative;

  .dropdown {
    background-color: $menu-item-bg-color;
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    border-radius: 6px;
    padding: 10px 0px;

    .dropdownItem {
      position: relative;
      @include reset-link;
      display: flex;
      padding: 8px 13px;
      align-items: center;
      font-size: 10px;
      gap: 1rem;
      color: $unselected-menu-item;

      .dropdownIcon {
        fill: $unselected-menu-item;
      }

      &.activeLink {
        color: $selected-sub-menu-item;

        .dropdownIcon {
          fill: $selected-sub-menu-item;
        }
      }
    }
  }

  @include tablet {
    .dropdownContainer {
      padding: 0px;
      border-radius: 0px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .dropdown {
      padding: 16px 10px;
      border-radius: 0px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      display: block;
      position: absolute;
      min-width: 160px;
      left: 98%;
      top: 13%;
      opacity: 1;
      animation: dropDownFadeIn 0.3s ease-in-out;
      z-index: 11;

      .dropdownItem {
        margin-left: 0px;
        padding: 6px 0px;
      }
    }
  }
}

.mainMenuItem {
  display: flex;
  align-items: center;
}

.g42Logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.link {
  @include reset-link;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  background: $menu-color;
  font-size: 14px;
  transition: opacity 0.3s ease;
  justify-content: space-between;
  height: 48px;

  @include tablet {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 74px;
  }

  .menuItemOption {
    display: flex;
    align-items: center;
    font-size: 12px;

    @include tablet {
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 9px;
      height: 80%;
    }
  }

  .expandDown {
    fill: $unselected-menu-item;

    @include tablet {
      display: none;
    }
  }

  .rotateChevron {
    fill: $selected-menu-item;
    transform: rotate(180deg);
  }

  .linkText {
    opacity: 1; // hide text when collapsed
  }
}

.activeLink {
  font-weight: 600;
  background: $menu-item-bg-color;
}

.linkText {
  white-space: pre-line;
  color: $unselected-menu-item;
  padding: 0 8px;
  text-align: center;

  // @include tablet {
  //   display: none;
  // }
}

.activeLink .linkText {
  color: $selected-menu-item;
}

.icon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  fill: $unselected-menu-item;

  @include tablet {
    margin-right: 0px;
  }
}

.activeLink .icon {
  fill: $selected-menu-item;
}

.header {
  display: none;
}

.bottomFooterWrapper {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.g42logo {
  border-left: 1px solid #2f3849;
  padding-left: 14px;
  margin-left: 25px;
}

.headerBit {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid $card-color;
}

.miniMenuWrapper {
  padding: 10px;
  position: relative;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include tablet {
    padding: 0px;
  }
}

.logOut {
  display: none;
}

.signOut {
  margin-left: 24px;
  margin-bottom: 24px;
  width: 100%;
  display: block;
  justify-content: center;
  align-items: center;

  .btnOptions {
    display: flex;
    gap: 1rem;
  }

  .signOutButton {
    display: none;
  }

  @include tablet {
    margin-left: 0px;
    display: flex;
  }
}

.version {
  margin-top: auto;
  font-size: 12px;
  line-height: 26px;
}

.versionMini {
  margin: 5px 0 0;
  font-size: 12px;
}

.collapsedLogo {
  display: none;
  @include tablet {
    display: inline-block;
    height: 27.75px;
  }
}

.expandedLogo {
  display: inline-block;
  @include tablet {
    display: none;
  }
}
