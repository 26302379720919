@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  display: flex;

  @include tablet-portrait {
    flex-direction: column;
  }
}

.chart {
  width: 100%;
  height: 440px;
  margin-bottom: 10px;
  padding: 16px;
  border-radius: 8px;
  background-color: $panel-color;

  @include tablet {
    height: 312px;
  }
}
