@import "src/styles/variables";
@import "src/styles/mixins";

.chatFooter {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: $chat-input-wrapper-color;
  border-radius: 8px 8px 0 0;
  -webkit-border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -ms-border-radius: 8px 8px 0 0;
  -o-border-radius: 8px 8px 0 0;
}

.promptWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.examplePrompt {
  display: flex;
  cursor: pointer;
  width: 100%;
  flex-direction: row;
  color: $chat-bubble-system-bg;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
.promptText {
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: 0.024px;
}

.chevron {
  display: inline-block;
  transition: transform 0.3s ease;
}

.chevronUp {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.chevronDown {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
