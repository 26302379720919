.tokenSecondary {
  color: var(--analog-text-secondary);
}

.tokenPrimary {
  color: var(--analog-text-primary);
}

.statValue {
  display: flex;
  align-items: center;
  gap: 4px;
}
