@import "src/styles/mixins";

.content {
  @include panel;
  height: 512px;
  padding: 16px;
  margin-bottom: 16px;
}

.addBorder {
  border: 1px solid $border-color;
}

.lineChart {
  composes: content;
  height: 350px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.bigTitle {
  font-size: 18px;
  flex-grow: 1;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.globalTooltip {
  position: relative;
  top: 10px;
  display: flex;
  flex-direction: row;
  color: $performance-management-tooltip-title;
  gap: 1rem;
  font-size: 14px;

  .tooltipItem {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    color: $performance-management-tooltip-title;
    &::after {
      content: "|";
    }
    &:last-child::after {
      content: "";
    }
  }

  .bold {
    font-weight: bold;
    color: $performance-management-tooltip-text;
  }

  .tsbLow {
    color: $tsbLow;
  }

  .tsbHigh {
    color: $tsbHigh;
  }
}

.selectors {
  display: flex;
  max-width: 70%;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
  flex-grow: 1;
  flex-shrink: 0;

  @include tablet {
    width: 50%;
  }
}

.stub {
  @include bounded-chart-stub;
}

.bold {
  font-weight: bold;
  text-transform: capitalize;
}

.clearMarkline {
  @include reset-button;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 16px;
  padding: 10px 20px;
  border: 1px solid $accent-primary;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $accent-primary;
  background: $account-btn-bg;

  & svg {
    height: 20px;
    width: 20px;
    fill: $accent-primary;
  }
}
