.analog-spinner {
  display: inline-block;
  flex-shrink: 0;
  border-radius: 50%;
}

.analog-spinner--xs {
  width: 12px;
  height: 12px;
}

.analog-spinner--s {
  width: 16px;
  height: 16px;
}

.analog-spinner--m {
  width: 24px;
  height: 24px;
}

.analog-spinner--l {
  width: 32px;
  height: 32px;
}

.analog-spinner--xl {
  width: 40px;
  height: 40px;
}

.analog-spinner--xxl {
  width: 48px;
  height: 48px;
}

.analog-spinner__inner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  animation: rotation 0.8s infinite linear;
}

.analog-spinner--xl .analog-spinner__inner,
.analog-spinner--xxl .analog-spinner__inner {
  animation-duration: 1.1s;
}

.analog-spinner--xs .analog-spinner__inner {
  border-width: 1px;
}

.analog-spinner--neutral .analog-spinner__inner {
  /*border-color: var(--analog-neutral-500) transparent;*/
}

.analog-spinner--primary .analog-spinner__inner {
  border-color: var(--analog-interactive-primary) transparent;
}

.analog-spinner--success .analog-spinner__inner {
  border-color: var(--analog-support-success) transparent;
}

.analog-spinner--warning .analog-spinner__inner {
  border-color: var(--analog-support-warning) transparent;
}

.analog-spinner--error .analog-spinner__inner {
  border-color: var(--analog-support-error) transparent;
}

.analog-spinner--white .analog-spinner__inner {
  border-color: var(--analog-white) transparent;
}

.analog-spinner--dark .analog-spinner__inner {
  border-color: var(--analog-surface-canvas) transparent;
}

.analog-spinner--secondary .analog-spinner__inner {
  /*border-color: var(--analog-secondary-900) transparent;*/
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
