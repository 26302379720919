@import "src/styles/variables";
@import "src/styles/mixins";

$grid-gap: 16px;
$grid-gap-tablet: 10px;

.mainWrapper {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-wrap: wrap;
  margin: -#{$grid-gap/2};
  margin-top: 32px;

  @include tablet-portrait {
    margin: -#{$grid-gap-tablet/2};
    margin-top: 32px;
  }
}

.chart {
  height: 512px;
  width: calc(100% - #{$grid-gap});
  margin: #{$grid-gap/2};
  background-color: $panel-color;
  padding: 10px;
  padding-bottom: 16px;
  border-radius: 8px;
  border: 1px solid $border-color;

  @include tablet-portrait {
    width: 100%;
    margin: #{$grid-gap-tablet/2};
  }
}
