@import "src/styles/mixins";
@import "src/styles/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid $card-color;

  @include tablet {
    margin-bottom: 10px;
  }
}

.topLeft {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.btnOptions {
  display: flex;
}

.topMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @include tablet {
    margin-right: 0;
  }
}

.additionalComps {
  display: flex;
  align-items: center;
}

.signOutButton {
  margin-left: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.coachButton {
  margin-left: 16px;
}

.hideBottom {
  margin-bottom: 10px;
  border-bottom: 0;
}

.athleteGroup {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.backNav {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 10px;
  height: 40px;
  width: 40px;
  background-color: $card-color;
  border-radius: 5px;
  cursor: pointer;
  fill: $icon-color;
}
