.analog-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 8px;
  position: relative;
  border: 2px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.15s ease-out;
  outline: none;
  user-select: none;
}

.analog-button:disabled {
  pointer-events: none;
}

.analog-button--fullWidth {
  width: 100%;
}

.analog-button__text {
  display: flex;
  align-items: center;
  opacity: 1;
}

.analog-button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.analog-button--xs .analog-button__icon,
.analog-button--xs .analog-button__spinner {
  width: 14px;
  height: 14px;
}

.analog-button--s .analog-button__icon,
.analog-button--s .analog-button__spinner {
  width: 16px;
  height: 16px;
}

.analog-button--m .analog-button__icon,
.analog-button--m .analog-button__spinner {
  width: 20px;
  height: 20px;
}

.analog-button--l .analog-button__icon,
.analog-button--l .analog-button__spinner {
  width: 24px;
  height: 24px;
}

.analog-button__spinner {
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analog-button--loading .analog-button__text,
.analog-button--loading .analog-button__icon {
  opacity: 0;
}

.analog-button--loading .analog-spinner {
  position: absolute;
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
}

.analog-button--xs {
  height: var(--analog-component-size-xs);
  padding: 0 12px;
  gap: 4px;
}

.analog-button--s {
  height: var(--analog-component-size-s);
  padding: 0 16px;
  gap: 6px;
}

.analog-button--m {
  height: var(--analog-component-size-m);
  padding: 0 24px;
}

.analog-button--l {
  height: var(--analog-component-size-l);
  padding: 0 32px;
}

.analog-button--icon.analog-button--xs {
  width: 24px;
  padding: 0;
}

.analog-button--icon.analog-button--s {
  width: var(--analog-component-size-s);
  padding: 0;
}

.analog-button--icon.analog-button--m {
  width: var(--analog-component-size-m);
  padding: 0;
}

.analog-button--icon.analog-button--l {
  width: var(--analog-component-size-l);
  padding: 0;
}

/* Contained Primary Button */

.analog-button--primary {
  background-color: var(--analog-interactive-primary);
  border-color: var(--analog-interactive-primary);
  color: var(--analog-interactive-text1);

  &:hover {
    background-color: var(--analog-interactive-primary-hover);
    border-color: var(--analog-interactive-primary-hover);
  }

  &:focus-visible {
    background-color: var(--analog-interactive-primary-hover);
    border-color: var(--analog-focus-primary);
  }

  &:active {
    background-color: var(--analog-interactive-primary-active);
    border-color: var(--analog-interactive-primary-active);
  }

  &:disabled {
    background-color: var(--analog-disabled1);
    border-color: var(--analog-disabled1);
    color: var(--analog-disabled2);
  }
}

.analog-button--secondary {
  background-color: var(--analog-interactive-secondary);
  border-color: var(--analog-interactive-border);
  color: var(--analog-interactive-text3);

  &:hover {
    background-color: var(--analog-interactive-secondary-hover);
    border-color: var(--analog-interactive-border-hover);
  }

  &:focus-visible {
    background-color: var(--analog-interactive-secondary-hover);
    border-color: var(--analog-focus-primary);
  }

  &:active {
    background-color: var(--analog-interactive-secondary-active);
    border-color: var(--analog-interactive-border-active);
  }

  &:disabled {
    background-color: var(--analog-disabled1);
    border-color: var(--analog-disabled1);
    color: var(--analog-disabled2);
  }
}

.analog-button--accent {
  background-color: var(--analog-interactive-accent);
  border-color: var(--analog-interactive-accent);
  color: var(--analog-interactive-text2);

  &:hover {
    background-color: var(--analog-interactive-accent-hover);
    border-color: var(--analog-interactive-accent-hover);
  }

  &:focus-visible {
    background-color: var(--analog-interactive-accent);
    border-color: var(--analog-focus-primary);
  }

  &:active {
    background-color: var(--analog-interactive-accent-active);
    border-color: var(--analog-interactive-accent-active);
  }

  &:disabled {
    background-color: var(--analog-disabled2);
    border-color: var(--analog-disabled2);
    color: var(--analog-disabled1);
  }
}

.analog-button--quiet {
  background-color: transparent;
  border-color: transparent;
  color: var(--analog-interactive-text3);

  &:hover {
    background-color: var(--analog-surface-layer1);
    border-color: var(--analog-surface-layer1);
    color: var(--analog-link-underline);
  }

  &:focus-visible {
    background-color: var(--analog-surface-layer1);
    border-color: var(--analog-focus-primary);
    color: var(--analog-link-primary);
  }

  &:active {
    background-color: var(--analog-surface-layer2);
    border-color: var(--analog-surface-layer2);
    color: var(--analog-link-visited);
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: var(--analog-disabled2);
  }
}

.analog-button--quietNeutral {
  background-color: transparent;
  border-color: transparent;
  color: var(--analog-text-secondary);

  &:hover {
    background-color: var(--analog-surface-layer3);
    border-color: var(--analog-surface-layer3);
  }

  &:focus-visible {
    background-color: var(--analog-surface-layer3);
    border-color: var(--analog-focus-primary);
  }

  &:active {
    background-color: var(--analog-surface-layer1);
    border-color: var(--analog-surface-layer1);
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: var(--analog-disabled2);
  }
}

.analog-button--quietNeutralDark {
  background-color: transparent;
  border-color: transparent;
  color: var(--analog-text-secondary);

  &:hover {
    background-color: var(--analog-surface-layer2);
    border-color: var(--analog-surface-layer2);
  }

  &:focus-visible {
    background-color: var(--analog-surface-layer2);
    border-color: var(--analog-focus-primary);
  }

  &:active {
    background-color: var(--analog-surface-canvas);
    border-color: var(--analog-surface-canvas);
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: var(--analog-disabled2);
  }
}

.analog-button--link {
  background-color: transparent;
  border-color: transparent;
  color: var(--analog-link-primary);
  text-decoration: underline;

  &:hover {
    color: var(--analog-link-underline);
  }

  &:focus-visible {
    background-color: var(--analog-surface-layer1);
    border-color: var(--analog-focus-primary);
    color: var(--analog-link-underline);
  }

  &:active {
    background-color: var(--analog-surface-layer2);
    border-color: var(--analog-surface-layer2);
    color: var(--analog-link-visited);
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: var(--analog-disabled1);
  }
}
