@import "src/styles/variables";
@import "src/styles/mixins";

// ToDo: lines 4-17 from session-expired-popup.module.scss - duplicates. Need to refactor.
.popupBox {
  position: fixed;
  z-index: 200;
  background: rgba($pop-up-bg-color, 0.8);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  background: $menu-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.inputBox {
  position: relative;
  width: 45vw;
  padding: 20px;

  @include tablet {
    width: 72vw;
  }

  @include tablet-portrait {
    width: 95vw;
  }
}

.successBox {
  display: flex;
  height: 499px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infoIcon {
  height: 10px;
  width: 10px;
  cursor: pointer;
  fill: $accent-primary;
}

.closeButton {
  cursor: pointer;
  margin-left: auto;
  fill: $accent-primary;
}

.formWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.athleteDetailSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: $card-color;

  .avatarPictureIcon {
    width: 80px;
    position: relative;
    border-radius: 8px;
    height: 80px;
    object-fit: cover;
    background: linear-gradient(45deg, black, transparent);
  }

  .firstName {
    margin-right: 4px;
    line-height: 150%;
    font-weight: 300;
  }

  .xAvatarComponents {
    display: flex;
    position: relative;
    flex-direction: row;
    height: 24px;
  }

  .lastName {
    line-height: 150%;
    font-weight: 600;
  }

  .xAvatarComponentsParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px 4px;
  }

  .flagIcon {
    position: absolute;
    height: 56.25%;
    width: 100%;
    top: 21.88%;
    right: 0%;
    bottom: 21.88%;
    left: 0%;
    border-radius: 2px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }

  .flags {
    width: 24px;
    position: relative;
    height: 24px;
  }

  .frameContainer {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .xAvatarComponents2 {
    align-self: stretch;
    position: relative;
    height: 18px;
    font-size: 12px;
    color: $athlete-card-group-color;
  }

  .frameGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px 0px;
  }

  .yrs {
    position: absolute;
    top: 0%;
    left: 0%;
    line-height: 150%;
    font-weight: 500;
  }

  .xAvatarComponents3 {
    width: 54px;
    position: relative;
    height: 18px;
  }

  .frameChild {
    width: 1px;
    position: relative;
    border-radius: 32px;
    background-color: #2f3849;
    height: 16px;
  }

  .xAvatarComponentsGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px 16px;
    font-size: 12px;
    color: $athlete-card-info-color;
  }

  .frameParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px 0px;
  }

  .athleteCard {
    position: relative;
    border-radius: 12px;
    background-color: $athlete-card-bg-color;
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 12px 8px 8px;
    box-sizing: border-box;
    gap: 0px 16px;
    text-align: left;
    font-size: 16px;
    color: $athlete-card-color;
    min-width: 350px;
    font-family: Montserrat;
  }

  .details {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .detail {
      height: 35px;
      border-radius: 6px;
      padding: 2px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;

      &:nth-child(odd) {
        background-color: $detail-odd-color;
      }
      &:nth-child(even) {
        background-color: $detail-even-color;
      }

      .label {
        color: $detail-label-color;
      }

      .value {
        color: $detail-value-color;
      }
    }
  }
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid $account-button-color;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.coachInputSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.coachInputSection > :last-child {
  margin-top: auto;
}

.message {
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.errorMessage {
  color: rgba($error-text, 0.8);
  font-weight: 600;
  font-size: 12px;
}

.successMessage {
  font-weight: 500;
  font-size: 12px;
  color: $accent-primary;
}

.span {
  font-weight: 600;
}
.of71 {
  color: $detail-value-color;
}
.of7 {
  position: relative;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 16px;
  text-align: left;
  color: $sign-in-button-color;
  font-family: Montserrat;
}

.footer {
  margin-top: 16px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
}

.rectangleDiv {
  width: 12px;
  height: 2px;
  position: relative;
  border-radius: 12px;
  background-color: $rectangularDivBackgroundColor;
}

.selectedDiv {
  background-color: $sign-in-button-color;
}

.previousButton {
  @include reset-button;
  height: 40px;
  padding: 4px 16px;
  border: 1px solid $accent-primary;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $accent-primary;
  background: $account-btn-bg;
}

.previousButton:disabled {
  background-color: $previousDisabledBackgroundColor;
  color: $previousDisabledColor;
  border: $previousDisabledBorder;
  cursor: not-allowed;
}

.saveButton {
  margin-left: 0px;
  width: 128px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 600;
}

.saveButton:disabled {
  cursor: not-allowed;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.thankYouForContainer {
  color: $primary-card-text-color;
  font-size: 20px;
  text-align: center;
  line-height: 130%;
  margin-top: 53px;
  margin-bottom: 61px;
}

.groupItem {
  width: 90px;
  height: 90px;
}

.doneButton {
  position: relative;
  line-height: 150%;
  font-weight: 600;
  border-radius: 8px;
  background-color: #f48371;
  width: 226px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  box-sizing: border-box;
  font-size: 14px;
  color: #f3f3f6;
  margin-left: 0;
}
