@import "src/styles/variables";
@import "src/styles/mixins";

.healthTab {
  background-color: $card-color;
  border-radius: 10px;
  height: 100%;
}

.header {
  background-color: $menu-color;
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  padding: 10px;
  align-items: center;
  border-radius: 10px;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 1rem;
  }
}

.newNote {
  background: transparent;
  border: 1px solid $account-button-color;
  color: $account-button-color;
  font-size: 14px;
}

.NotesBox {
  padding: 10px;
  background-color: $menu-color;
  margin: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.noteBoxBottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  color: #6b7282;

  .addedByWrapper {
    display: flex;
    justify-content: left;
  }

  .editableWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.editNotes {
  color: $accent-primary;
  cursor: pointer;
  margin-right: 0.5rem;
}

.deleteNotes {
  color: $primary-text-color;
  cursor: pointer;
}

.editIcon {
  position: relative;
  top: 2px;
  left: 2px;
  fill: $accent-primary !important;
}

.noNotes {
  display: flex;
  align-self: center;
  text-align: center;
  justify-content: center;
  margin-top: 25vh;
  color: #6b7282;
}
