@import "src/styles/variables";
@import "src/styles/mixins";

.button {
  @include reset-button;
  background-color: transparent;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: $primary-text-color;
  margin-right: 4px;
  text-align: left;
  cursor: default;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.button:last-child {
  margin-right: 0;
}

.smallChartLegend {
  composes: button;
  font-size: 10px;
}

.bigChartLegend {
  composes: button;
  font-size: 12px;
}

.icon {
  margin-right: 4px;
  width: 9px;
}

.disabled {
  opacity: 0.7;
  filter: grayscale(100%);
}

.clickable {
  cursor: pointer;
}

.ranges {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
