.analog-avatar {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
}

.analog-avatar__circle {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--analog-surface-undercanvas);
  border: 1px solid var(--analog-border-middle);
  user-select: none;
  overflow: hidden;
}

.analog-avatar--xs .analog-avatar__circle {
  width: var(--analog-component-size-xs);
  height: var(--analog-component-size-xs);
}

.analog-avatar--s .analog-avatar__circle {
  width: var(--analog-component-size-s);
  height: var(--analog-component-size-s);
}

.analog-avatar--m .analog-avatar__circle {
  width: var(--analog-component-size-m);
  height: var(--analog-component-size-m);
}

.analog-avatar--l .analog-avatar__circle {
  width: var(--analog-component-size-l);
  height: var(--analog-component-size-l);
}

.analog-avatar--round .analog-avatar__circle {
  border-radius: 50%;
}

.analog-avatar--rounded .analog-avatar__circle {
  border-radius: 8px;
}

.analog-avatar--xs.analog-avatar--rounded .analog-avatar__circle {
  border-radius: 4px;
}

.analog-avatar__image {
  width: 100%;
}

.analog-avatar__letters {
  color: var(--analog-text-inverse);
}

.analog-avatar__details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.analog-avatar__text {
  color: var(--analog-text-primary);
}

.analog-avatar__subtext {
  color: var(--analog-text-secondary);
}

.analog-avatar--reverse {
  flex-direction: row-reverse;
}

.analog-avatar--reverse .analog-avatar__details {
  text-align: right;
  align-items: flex-end;
}

.analog-avatar--l {
  gap: 12px;
}

.analog-avatar--m {
  gap: 12px;
}

.analog-avatar--s {
  gap: 8px;
}

.analog-avatar--xs {
  gap: 8px;
}
