.analog-radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  flex-shrink: 0;
}

.analog-radio__input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.analog-radio__check {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--analog-input-border);
  transition: ease-in 0.12s;
}

.analog-radio__mark {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: var(--analog-white);
}

.analog-radio__text {
  display: inline-flex;
  align-items: center;
  width: auto;
  transition: 0.1s ease-in-out;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
  color: var(--analog-text-primary);
}

.analog-radio:focus-within .analog-radio__check,
.analog-radio__input:hover + .analog-radio__check {
  border-color: var(--analog-focus-primary);
}

.analog-radio:active .analog-radio__check {
  border-color: var(--analog-active-primary);
}

.analog-radio__input:checked + .analog-radio__check {
  background-color: var(--analog-interactive-primary);
  border-color: var(--analog-interactive-primary);
}

.analog-radio__input:checked + .analog-radio__check .analog-radio__mark {
  opacity: 1;
}

.analog-radio:focus-within .analog-radio__input:checked + .analog-radio__check,
.analog-radio__input:checked:hover + .analog-radio__check {
  background-color: var(--analog-interactive-primary-hover);
  border-color: var(--analog-interactive-primary-hover);
}

.analog-radio:active .analog-radio__input:checked + .analog-radio__check {
  background-color: var(--analog-interactive-primary-active);
  border-color: var(--analog-interactive-primary-active);
}

.analog-radio--disabled {
  pointer-events: none;
  cursor: default;
}

.analog-radio--disabled .analog-radio__check {
  border-color: var(--analog-disabled2);
}

.analog-radio--disabled .analog-radio__input:checked + .analog-radio__check {
  border-color: var(--analog-disabled2);
  background-color: var(--analog-disabled1);
}

.analog-radio--disabled .analog-radio__mark {
  background-color: var(--analog-text-field-disabled2);
}

.analog-radio--disabled .analog-radio__text {
  color: var(--analog-text-field-disabled2);
}
