@import "src/styles/mixins";

.topMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @include tablet {
    margin-bottom: 10px;
  }
}

.rightMost {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
}

.topHeader {
  display: flex;
  align-items: center;
}

.topHeaderCls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
}

.contentArea {
  padding: 10px 16px;
  border-radius: 10px;
}

.contentHeader {
  composes: topHeader;
  margin-bottom: 10px;
}

.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  padding: 0;
  margin: 0 auto 0 0;

  @include tablet {
    font-size: 20px;
    line-height: 24px;
  }

  @include tablet-portrait {
    max-width: 340px;
  }
}

.content {
  $margin: 32px;
  flex: 1;
  overflow-y: scroll;
  overflow-y: overlay;
  overflow-x: hidden;
  margin-right: -$margin;
  padding-right: $margin;
}

.contentWrapper {
  display: flex;
}
