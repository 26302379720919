@import "src/styles/variables";
@import "src/styles/mixins";

// ToDo: lines 4-17 from session-expired-popup.module.scss - duplicates. Need to refactor.
.popupBox {
  position: fixed;
  z-index: 20;
  background: rgba($pop-up-bg-color, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.countDown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.container {
  width: 100%;
  margin-top: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #f3f3f6;
  box-sizing: border-box;

  @include tablet {
    flex-direction: column;
  }
}

.calendar {
  background-color: $pop-up-calendar-bg-color;
  padding: 10px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.inputField {
  flex: 1;
}

.inputWrapper {
  & .icon {
    margin: 10px 0px;
    width: 100%;
  }
}

.subTitle {
  margin: 10px 0px;
  font-size: 16px;
  font-weight: 500;
  color: rgba($accent-primary, 0.5);
}

.options {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 15px;
}

.box {
  position: relative;
  margin: 0 auto;
  height: auto;
  background: $popup-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: auto;
}

.inputBox {
  width: auto;
  padding: 32px;

  @include tablet {
    width: 70vw;
  }

  @include tablet-portrait {
    width: 72vw;
  }
}

.closeButton {
  cursor: pointer;
  margin-left: auto;
  fill: $accent-primary;
}

.footer {
  margin-top: 38px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.addButton {
  width: 128px;
  padding: 20px 0;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: space-between;
}

.errorInput {
  border: 1px solid red;
  background-color: rgba($error-text, 0.2);
}

.errorMessage {
  color: rgba($error-text, 0.8);
  margin-top: 20px;
  font-size: 14px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
