@import "src/styles/variables";

.wrapper {
  position: relative;
}

.calendar {
  position: absolute;
  left: 14px;
  top: 12px;
  pointer-events: none;
}
