@import "src/styles/mixins";

.wrapper {
  margin-left: 0;
  cursor: pointer;
  display: flex;
  @include tablet {
    margin-left: -38px;
  }
  gap: 1rem;
  align-items: center;
  position: relative;
}

.dropdown {
  padding: 0 10px;
  border-radius: 10px;
  display: block;
  position: absolute;
  background-color: $dropdown-bg;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
  right: 0;
  top: 100%;
  opacity: 1;
  animation: dropDownFadeIn 0.3s ease-in-out;
}

.avatar {
  text-align: center;
}

.image {
  max-width: 48px;
  max-height: 48px;
  border-radius: 10px;
}

.info {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: 300;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.2px;
}
.strong {
  font-weight: 600;
}

.role {
  display: flex;
  flex-direction: row-reverse;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: normal;
}

@keyframes dropDownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
