@import "src/styles/mixins";

.content {
  @include panel;
  height: 512px;
  padding: 16px;
  margin-top: 16px;
}

.selectionVariables {
  display: flex;
  gap: 1rem;
}

.powerTypeSelector {
  width: 180px;
}

.selectDropDown {
  background-color: transparent;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
  color: $dropdown-border-color;
  border: 1px solid $dropdown-border-color;
  background-color: $dropdown-bg-color;
  font-family: inherit;
  width: 100%;

  & option {
    background-color: $dropdown-bg;
    color: $dropdown-text-color;
  }
}

.lineChart {
  height: 450px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stub {
  @include bounded-chart-stub;
}

.bold {
  font-weight: bold;
  text-transform: capitalize;
}

.bigTitle {
  font-size: 18px;
  flex-grow: 3;
}
