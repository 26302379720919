@import "src/styles/variables";

$breakpoints: (
  portrait: 1193px,
  tablet: 1439px,
);

@mixin reset-link {
  text-decoration: none;
  color: $primary-text-color;
}

@mixin reset-button {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  background: none;
  color: inherit;
}

@mixin panel {
  background-color: $panel-color;
  border-radius: 8px;
}

@mixin bounded-chart-stub {
  @include panel;
  height: $bounded-charts-height;

  & + & {
    margin-top: 16px;
  }
}

$tablet-width: 1024px;
$desktop-width: 1366px;

@mixin desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpoints) {
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);

    @media (max-width: #{$mq-breakpoint}) {
      @content;
    }
  } @else {
    @content;
  }
}
