@import "src/styles/variables";
@import "src/styles/mixins";

.tab {
  @include reset-button;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: $side-tabs-width;
  padding: 8px 4px 8px 15px;
  margin-bottom: 16px;
  color: rgba($primary-text-color, 0.6);
  background: $panel-color;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  border-radius: 6px;

  @include tablet-portrait {
    width: $side-tabs-width-tablet;
    padding: 5px 5px 5px 7px;
  }
}

.tabActive {
  color: $primary-text-color;
  font-weight: 600;
  background-color: $card-color;

  &:before {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: $accent-primary;
    width: 6px;
    border-radius: 6px 0 0 6px;
  }
}

.icon {
  flex-shrink: 0;
  fill: $accent-primary;
}
