.analog-typography--h1 {
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 1.2;
}

.analog-typography--h1.bold {
  font-weight: 700;
}

.analog-typography--h1.light {
  font-weight: 300;
}

.analog-typography--h2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1.2;
}

.analog-typography--h2.bold {
  font-weight: 700;
}

.analog-typography--h2.light {
  font-weight: 300;
}

.analog-typography--h3 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.3;
}

.analog-typography--h3.bold {
  font-weight: 700;
}

.analog-typography--h3.light {
  font-weight: 300;
}

.analog-typography--h4 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.3;
}

.analog-typography--h4.bold {
  font-weight: 700;
}

.analog-typography--h4.light {
  font-weight: 300;
}

.analog-typography--headline {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.analog-typography--headline.bold {
  font-weight: 600;
}

.analog-typography--headline.light {
  font-weight: 300;
}

.analog-typography--body {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;
}

.analog-typography--body.bold {
  font-weight: 600;
}

.analog-typography--body.normal {
  font-weight: 400;
}

.analog-typography--body.light {
  font-weight: 300;
}

.analog-typography--subtitle {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.02rem;
}

.analog-typography--subtitle.bold {
  font-weight: 700;
}

.analog-typography--subtitle.normal {
  font-weight: 400;
}

.analog-typography--subtitle.light {
  font-weight: 300;
}

.analog-typography--caption {
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 1.5;
}

.analog-typography--caption.bold {
  font-weight: 600;
}

.analog-typography--caption.light {
  font-weight: 300;
}

.analog-typography--footnote {
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 1.5;
  letter-spacing: 0.02rem;
}

.analog-typography--footnote.bold {
  font-weight: 700;
}

.analog-typography--footnote.light {
  font-weight: 300;
}

.analog-typography--button-m {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
}

.analog-typography--button-s {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5;
}
