@import "src/styles/variables";

.timeframe-selector__select {
  width: 180px;
  z-index: 10;
}

.timeframe-selector__select .react-select {
  &__control {
    background: transparent;
    min-height: 40px;
    border: 1px solid $card-color;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: none;
    font-size: 14px;

    &:hover {
      border-color: $card-color;
    }
  }

  &__single-value {
    color: $secondary-text-color;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    color: $secondary-text-color;
  }

  &__menu {
    background: $popup-color;
    color: $secondary-text-color;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
    margin: 0;
    padding: 0;
  }

  &__option {
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;

    &:hover,
    &:focus,
    &--is-focused {
      background-color: $hover-color;
    }

    &--is-selected,
    &--is-selected:hover {
      background-color: $accent-primary;
    }
  }
}

.timeframe-selector__select_predication {
  width: 30vw;

  .react-select__control {
    padding-left: 0;
  }
}

.timeframe-selector__select--small {
  width: 142px;
}

.timeframe-selector__select--header {
  z-index: 8;
}

.timeframe-selector__select--small .react-select {
  &__control {
    border: 1px solid #333b4a;
    padding-left: unset;
  }
}
