.feedback {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  min-height: 312px;
  padding: 25px 15px;
}

.feedbackCard {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  gap: 8px;
  margin-block-start: 8px;
  background-color: var(--analog-surface-layer1);
  padding: 15px;
  border-radius: 4px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--analog-overlay-primary);
  display: block;
}
