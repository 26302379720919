.parameter {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.parameterName {
  color: var(--analog-text-primary);
}

.parameterProgress {
  height: 6px;
  width: 100%;
  background-color: var(--analog-surface-canvas);
  border-radius: 3px;
}

.parameterProgressInner {
  height: 100%;
  width: 100%;
  max-width: 0;
  border-radius: 3px;
  background: #00b2ff;
  animation: progressBar 1s ease-out;
  animation-fill-mode: both;
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
