@import "src/styles/variables";
@import "src/styles/mixins";

.header {
  font-size: 25px;
  font-weight: 700;
}

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 15px;
  background-color: $menu-color;
  margin-bottom: 10px;
  border-radius: 10px;
}

.searchFilter {
  display: flex;
}

.search {
  width: 228px;
}

.button {
  font-size: 14px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  font-weight: 600;

  @include tablet {
    width: 42px;
  }
}

.exportDropdown {
  width: 100%;
  display: flex;
  align-items: center;

  @include tablet {
    width: auto;
  }
}

.contentWrapper {
  padding: 0;
}

.contentWrapper > div:nth-child(1) {
  border-radius: 10px;
  height: 78vh;
}

.tableData {
  overflow-y: auto !important;
}

.rightOptions {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.exportButton {
  width: 122px;
  font-size: 14px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  font-weight: 500;

  @include tablet {
    width: 75px;
  }
}

.athleteCell {
  display: flex;
  align-items: center;
}
