@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  background: $card-color;

  @include tablet {
    padding: 8px 10px;
  }

  & + & {
    margin-left: 18px;
  }
}

.containerCyclist {
  flex: 40%;
  margin-left: 0;
  height: 132px;
  & + & {
    margin-left: 0;
  }
  @include tablet {
    flex: 1;
    padding: 8px 10px;
    height: 100%;
  }
  @include tablet-portrait {
    flex: 1;
    height: 100%;
  }
}

.line {
  display: flex;
  justify-content: space-between;
}

.lineMargin {
  margin: 13px 0;
}

.value {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;

  @include tablet-portrait {
    font-size: 24px;
    line-height: 29px;
  }
}

.title {
  font-weight: 500;
  min-height: 37px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $secondary-text-color;
}

.average {
  color: $primary-text-color;
}

.lastUpdated {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  font-weight: 300;
}

.grey {
  color: #8c93a6;
}

.source {
  margin-right: 0;
  justify-content: flex-end;
}
