@import "src/styles/mixins";

.button {
  @include reset-button;
  padding: 9px 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: 1px solid $card-color;
  background: rgba($card-color, 0.5);
}

.text {
  margin-left: 6px;
  color: $primary-text-color;
  font-size: 14px;
  line-height: 17px;
}
