@import "src/styles/mixins";
@import "src/styles/variables";

.label {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: flex-start;
}

.athleteLabel {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.labelColor {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 4px;
  flex-shrink: 0;
}

.labelName {
  @include reset-button;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.athleteLabelName {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 150%;
  font-weight: 500;
}

.labelUpdated {
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  color: rgba($primary-text-color, 0.6);
}

.dropdownIcon {
  margin-left: 4px;
}

.maxValue {
  display: flex;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  font-family: inherit;
  color: $accent-primary;
}

.athleteMaxValue {
  display: flex;
  justify-content: center;
  font-size: 10px;
  letter-spacing: 0.02em;
  line-height: 150%;
  font-family: inherit;
  color: $secondary-text-color;
}
