.analog-collapsible {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.analog-collapsible__underlay {
  opacity: 0;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--analog-surface-layer2);
  transition: opacity 0.3s ease;
}

.analog-collapsible:hover {
  .analog-collapsible__underlay {
    opacity: 1;
  }
}

.analog-collapsible__header {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  background-color: transparent;
  transition: background-color 0.3s ease;
  text-align: left;
}

.analog-collapsible__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 8px;
  color: var(--analog-menu-icon-unselected);
}

.analog-collapsible__text {
  flex-grow: 1;
  color: var(--analog-menu-text-unselected);
}

.analog-collapsible__chevron {
  margin-inline-start: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--analog-menu-icon-unselected);
  transition: transform 0.25s ease;
}

.analog-collapsible__header:hover,
.analog-collapsible__header:focus-visible {
  background-color: var(--analog-surface-layer2);

  .analog-collapsible__icon,
  .analog-collapsible__chevron {
    color: var(--analog-menu-icon-hover);
  }

  .analog-collapsible__text {
    color: var(--analog-menu-text-hover);
  }
}

.analog-collapsible__content {
  position: relative;
  z-index: 2;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: var(--analog-surface-layer2);
}

.analog-collapsible--open {
  background-color: var(--analog-surface-layer2);

  .analog-collapsible__content {
    max-height: 200px;
  }

  .analog-collapsible__icon {
    color: var(--analog-menu-text-selected);
  }

  .analog-collapsible__chevron {
    color: var(--analog-menu-text-selected);
    transform: rotate(180deg);
  }

  .analog-collapsible__text {
    color: var(--analog-menu-text-selected);
  }
}

.analog-collapsible--closed .analog-collapsible__content {
  max-height: 0;
}
