@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  display: flex;
  padding: 0px 32px 0 0px;
  height: 100%;
}

.page {
  width: calc(100% - var(--menubar-expanded-width) - var(--margin));
  margin-left: var(--margin);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  @include tablet {
    width: calc(100% - var(--menubar-collapsed-width) - var(--margin));
    height: calc(100vh - #{$menubar-height});
  }
}

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 15px;
  background-color: $menu-color;
  margin-bottom: 10px;
  border-radius: 10px;
}

.searchFilter {
  display: flex;
}

.buttonBlock {
  position: relative;
  z-index: 5;
  text-align: right;
}

.button {
  @include reset-button;
  height: 32px;
  padding: 4px 16px;
  border: 1px solid $accent-primary;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $accent-primary;
  background: $account-btn-bg;
}

.athleteCardWrapper {
  width: 100%;
  margin-top: 24px;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #f3f3f6;
}
