.analog-datepicker * {
  box-sizing: border-box;
}

.analog-datepicker {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.analog-datepicker .analog-dropdown__anchor {
  width: 100%;
}

.analog-datepicker__toggle .analog-input__input {
  user-select: none;
}

.analog-datepicker__toggle {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 0 4px 0 12px;
  cursor: pointer;
  outline: none;
}

.analog-datepicker--light .analog-datepicker__toggle {
  background-color: var(--analog-text-field-background2);
  border: 1px solid var(--analog-text-field-border2);
}

.analog-datepicker--dark .analog-datepicker__toggle {
  background-color: var(--analog-surface-layer2);
  border: 1px solid var(--analog-surface-layer2);
}

.analog-datepicker--s .analog-datepicker__toggle {
  height: var(--analog-component-size-s);
}

.analog-datepicker--m .analog-datepicker__toggle {
  height: var(--analog-component-size-m);
}

.analog-datepicker--l .analog-datepicker__toggle {
  height: var(--analog-component-size-l);
}

.analog-datepicker__toggle:hover,
.analog-datepicker__toggle:focus-visible {
  border-color: var(--analog-border-primary-inverse);
}

.analog-datepicker__toggle--focused,
.analog-datepicker__toggle--focused:hover {
  border-color: var(--analog-active-primary);
}

.analog-datepicker__toggle--invalid,
.analog-datepicker__toggle--invalid:hover {
  border-color: var(--analog-support-error);
}

.analog-datepicker__toggle-placeholder {
  color: var(--analog-text-placeholder);
  font-weight: 400;
}

.analog-datepicker__toggle-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: var(--analog-text-primary);
}

.analog-datepicker__toggle-value {
  color: var(--analog-text-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}

.analog-datepicker__toggle--disabled {
  pointer-events: none;
  user-select: none;
  background-color: var(--analog-text-field-disabled2);
  border-color: var(--analog-disabled1);
  justify-content: space-between;
}

.analog-datepicker__toggle--disabled .analog-datepicker__toggle-value {
  color: var(--analog-disabled1);
}
