@import "src/styles/variables";

.app {
  background: $main-bg-color;
  color: $primary-text-color;
  height: 100vh;
}
@media (max-width: 768px) {
  .app {
    height: 100%;
  }
}

body,
ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
input,
button {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

input {
  outline: 0 none;
}

ul,
ol {
  list-style: none;
}

button,
input[type="button"],
input[type="submit"] {
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:focus,
button:focus,
input:focus {
  outline: 0;
}

// Webkit
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  background-color: rgba(64, 68, 81, 1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(141, 146, 165, 1);
}

// Mozilla
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(64, 68, 81, 1) transparent;
}
