@import "src/styles/variables";
@import "src/styles/mixins";

.chatBubble {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: $chat-bubble-system-bg;
  border-radius: 16px 16px 16px 0;
  -webkit-border-radius: 16px 16px 16px 0;
  -moz-border-radius: 16px 16px 16px 0;
  -ms-border-radius: 16px 16px 16px 0;
  -o-border-radius: 16px 16px 16px 0;
}

.chatBubbleText {
  font-size: 12px;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: 0.024px;
  color: $chat-text-color;
  width: 100%;
  overflow: hidden;
}

.chatBubbleUser {
  background: $chat-bubble-user-bg;
  border-radius: 16px 16px 0 16px;
  -webkit-border-radius: 16px 16px 0 16px;
  -moz-border-radius: 16px 16px 0 16px;
  -ms-border-radius: 16px 16px 0 16px;
  -o-border-radius: 16px 16px 0 16px;
}

.latexPart,
.fractionPart {
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  white-space: nowrap; /* Keep content on a single line */
}
