@import "src/styles/variables";
.current-date-calendar {
  .react-calendar {
    background: $pop-up-calendar-bg-color;
    border: none;
    color: $primary-text-color;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    width: 392px;
    padding: 24px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
  }

  .react-calendar__tile:disabled {
    cursor: auto;
    color: $pop-up-calendar-disabled-day-color;
    background-color: $pop-up-calendar-bg-color;
  }

  .react-calendar__month-view__weekdays {
    font-size: 16px;
    font-weight: 700;
    color: $pop-up-calendar-week-color;
    text-transform: capitalize;
    & abbr {
      text-decoration: none;
    }
  }

  .react-calendar__navigation button,
  .react-calendar__tile {
    color: $primary-text-color;
  }

  .react-calendar__tile {
    height: 49px !important;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .react-calendar__navigation__label {
    font-size: 20px;
    line-height: 130%;
    font-weight: 700;
    color: $pop-up-calendar-selected-day-color;
  }

  .react-calendar__navigation__arrow {
    font-size: 26px;
    line-height: 0;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    font-weight: 600;
  }

  .react-calendar__navigation__prev-button:disabled,
  .react-calendar__navigation__next-button:disabled {
    cursor: auto;
    color: $pop-up-calendar-week-color;
    font-weight: 500;
  }

  .react-calendar__tile--now {
    background: unset;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: $hover-color;
  }

  .react-calendar__tile--hasActive,
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: $pop-up-calendar-selected-day-bg-color;
    color: $pop-up-calendar-selected-day-color;
    font-size: 16px;
    font-weight: 700;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: $pop-up-calendar-disabled-day-color;
  }

  .react-calendar__navigation button[disabled] {
    background-color: unset;
  }
}
