.select__toggle *,
.analog-select__content * {
  box-sizing: border-box;
}

.analog-select {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.analog-select .analog-dropdown__anchor {
  width: 100%;
}

.analog-select__anchor .analog-input__input {
  user-select: none;
}

.analog-select__content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--analog-surface-layer2);
  z-index: var(--layer-overlay);
  outline: none;
  overflow: hidden;
}

.analog-select__content-main {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.analog-select__option-wrapper {
  padding: 0 4px;
}

.analog-select__option {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  outline: none;
  gap: 8px;
  border: 0;
  border-radius: 8px;
  text-align: left;
  padding: 8px 16px;
  color: var(--analog-text-primary);
  background-color: var(--analog-surface-layer2);
  cursor: pointer;
  transition: 0.075s ease-out;
}

.analog-select__option-text {
  width: 100%;
}

.analog-select__option-left {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}

.select__option-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.select__option-checkMark {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select__option-right-icon {
  color: var(--analog-icon-primary);
}

.analog-select__option:hover,
.analog-select__option:focus-visible {
  background-color: var(--analog-surface-layer4);
}

.analog-select__option:active {
  background-color: var(--analog-surface-layer3);
}

.analog-select__option--active {
  background-color: var(--analog-surface-layer3);
}

.analog-select__option--active:hover,
.analog-select__option--active:focus-visible {
  background-color: var(--analog-surface-layer1);
}

.select__option-description {
  margin-top: 1px;
  flex-shrink: 0;
}

.select__option-description--neutral {
  color: var(--analog-text-secondary);
}

.select__option-description--success {
  color: var(--analog-support-success);
}

.select__option-description--warning {
  color: var(--analog-support-warning);
}

.select__option-description--error {
  color: var(--analog-support-error);
}

.select__option-description--info {
  color: var(--analog-support-info);
}

.analog-select__option-divider {
  display: flex;
  align-items: center;
}

.analog-select__option-dividerLine {
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  margin: 4px 0;
  background-color: var(--analog-border-primary);
}

.analog-select__option-heading {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-align: left;
  padding: 8px 16px;
  background-color: var(--analog-surface-layer2);
  color: var(--analog-text-primary);
}

.analog-select__option-heading-messages {
  flex-shrink: 0;
  text-align: left;
  padding: 8px 16px;
}

.analog-select__option-tooltip {
  color: var(--analog-icon-primary);
}

.analog-select__option-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--analog-icon-primary);
}

.analog-select__content-search {
  border-bottom: 1px solid var(--analog-border-primary);
  padding: 8px;
  z-index: var(--layer-normal);
}

.analog-select__empty-list {
  padding: 16px;
  color: var(--analog-surface-layer2);
}

.analog-select__content-footer {
  padding: 16px;
  border-top: 1px solid var(--analog-border-primary);
}

/* Select Toggle */

.analog-select__toggle {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 0 4px 0 12px;
  cursor: pointer;
  outline: none;
}

.analog-select--light .analog-select__toggle {
  background-color: var(--analog-text-field-background2);
  border: 1px solid var(--analog-text-field-border2);
}

.analog-select--dark .analog-select__toggle {
  background-color: var(--analog-surface-layer2);
  border: 1px solid var(--analog-surface-layer2);
}

.analog-select--s .analog-select__toggle {
  height: var(--analog-component-size-s);
}

.analog-select--m .analog-select__toggle {
  height: var(--analog-component-size-m);
}

.analog-select--l .analog-select__toggle {
  height: var(--analog-component-size-l);
}

.analog-select__toggle:hover,
.analog-select__toggle:focus-visible {
  border-color: var(--analog-border-primary-inverse);
}

.analog-select__toggle--focused,
.analog-select__toggle--focused:hover {
  border-color: var(--analog-active-primary);
}

.analog-select__toggle--invalid,
.analog-select__toggle--invalid:hover {
  border-color: var(--analog-support-error);
}

.analog-select__toggle-placeholder {
  color: var(--analog-text-placeholder);
  font-weight: 400;
}

.analog-select__toggle-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: var(--analog-icon-primary);
}

.analog-select__chevron {
  transition: transform 0.175s ease-in;
}

.analog-select__chevron--rotate {
  transform: rotate(180deg);
}

.analog-select__toggle-value {
  color: var(--analog-text-primary);
  font-weight: 400;
}

.analog-select__toggle--disabled {
  pointer-events: none;
  user-select: none;
  background-color: var(--analog-text-field-disabled2);
  border-color: var(--analog-disabled1);
  justify-content: space-between;
}

.analog-select__toggle--disabled .analog-select__toggle-value {
  color: var(--analog-disabled1);
}

.analog-select__toggle-spinner {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analog-select__content-spinner {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 0;
  text-align: left;
  padding: 8px 16px;
  background-color: var(--analog-white);
}
