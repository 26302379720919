@import "src/styles/variables";

.mmp-type-selector {
  .mmp-type__select {
    width: 200px;
    z-index: 20;
  }

  .mmp-type__select .react-select {
    &__control {
      background: transparent;
      min-height: 40px;
      border: 1px solid $card-color;
      border-radius: 6px;
      padding-left: 5px;
      cursor: pointer;
      box-shadow: none;
      font-size: 14px;

      &:hover {
        border-color: $card-color;
      }
    }

    &__single-value {
      color: $secondary-text-color;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      color: $secondary-text-color;
    }

    &__menu {
      background: $popup-color;
      color: $secondary-text-color;
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
      margin: 0;
      padding: 0;
    }

    &__option {
      cursor: pointer;
      font-size: 14px;
      border-radius: 5px;

      &:hover,
      &:focus,
      &--is-focused {
        background-color: $hover-color;
      }

      &--is-selected,
      &--is-selected:hover {
        background-color: $accent-primary;
      }
    }
  }

  .mmp-type__select_predication {
    width: 30vw;

    .react-select__control {
      padding-left: 0;
    }
  }

  .mmp-type__select--small {
    width: 142px;
  }

  .mmp-type__select--small .react-select {
    &__control {
      border: 1px solid #333b4a;
      padding-left: unset;
    }
  }
}
