@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 396px;
}

.control {
  width: 100%;
  padding: 0 10px;
  height: 40px;
  color: $primary-text-color;
  border-radius: 10px;
  border: 1px solid $account-type-active;
  background-color: $menu-color;
}

.searchIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
}
