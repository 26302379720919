@import "src/styles/mixins";
@import "src/styles/variables";

.smallFooter {
  margin: 0 -11px;
  padding: 0 10px;
}

.bigFooter {
  margin: 0 -16px;
  padding: 0 16px;
}

.scoreBarLegend {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 16px;
  height: 16px;
  border-top: 1px solid $border-color;
  padding-right: 12px;
}

.total {
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.input {
  height: 12px;
  width: 12px;
  margin-right: 10px;
  fill: $accent-primary;
}

.source {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 500;
}
