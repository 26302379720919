@import "src/styles/variables";
@import "src/styles/mixins";

.header {
  background-color: $menu-color;
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
}

.healthTab {
  background-color: $card-color;
  border-radius: 10px;
  padding-bottom: 1.5px;
}

.dropzoneStyle {
  border: "2px dashed #007BFF";
  border-radius: "5px";
  padding: "20px";
  text-align: "center";
  height: 200px;
  cursor: "pointer";
}

.file {
  color: #008b32;
}

.newNote {
  background: transparent;
  border: 1px solid $account-button-color;
  color: $account-button-color;
  font-size: 14px;
  width: fit-content;
  padding: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.attachmentWrapper {
  overflow: auto;
  max-height: 100px;
}

.attachFiles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-bottom: 10px;
  justify-content: space-between;
  background-color: $card-color;
  border-radius: 0 0 10px 10px;

  .attachFilesHeading {
    font-size: 12px;
  }

  .attachFilesData {
    margin-top: 5px;
  }

  .attachFileName {
    font-size: 12px;
    padding: 5px 0 5px 0;
    text-wrap: nowrap;
    white-space: nowrap;
  }

  .attachFileSize {
    justify-self: center;
    font-size: 12px;
    padding: 5px 0 5px 0;
  }

  .attachFileByName {
    justify-self: center;
    font-size: 12px;
    padding: 5px 0 10px 0;
  }

  .attachFileBtns {
    justify-self: center;
    padding: 5px 0 10px 0;
  }
}

.iconAttach {
  position: relative;
  top: 5px;
  margin-right: 5px;
  cursor: pointer;
}
