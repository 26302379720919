@import "src/styles/mixins";
@import "src/styles/variables";

.wrapper {
  width: 100%;
  margin-left: 0;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.button {
  width: 100%;
  @include reset-button;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border: 1px solid $accent-primary;
  border-radius: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13.5px;
  color: $accent-primary;
  background: $account-btn-bg;
}

.noCommentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.disabled {
  width: 100%;
  @include reset-button;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13.5px;
  background: rbga($comment-button-disabled-bg-color, 0.9);
  color: $sign-in-button-disabled-text-color;
  border: 1px solid $comment-button-disabled-bg-color;
}

.editComment {
  color: $accent-primary;
}

.editDisabled {
  color: $sign-in-button-disabled-text-color;
}

.tooltipContent {
  background-color: $tooltip-bg-color;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 8px;
}

.commentTooltipContent {
  background-color: $tooltip-bg-color;
  color: $tooltip-text-color;
  padding: 8px 12px;
  border: 1px solid $tooltip-border-color;
  border-radius: 4px;
  font-size: 10px;
  line-height: 150%;
  font-weight: 500;
}

.editCommentWrapper {
  cursor: pointer;
}
