@import "src/styles/variables";

.search-dropdown {
  .css-1napgqm-menu {
    height: 70vh;
    background: $menu-color;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 0;
    display: flex;

    & > div {
      width: 98%;
    }

    .css-xg5g4h {
      height: 100%;
      max-height: none;
    }

    .css-1gl4k7y {
      height: 100%;
    }
  }
}
