.analog-input {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}

.analog-input--light {
  background-color: var(--analog-text-field-background2);
  border: 1px solid var(--analog-text-field-border2);
}

.analog-input--dark {
  background-color: var(--analog-surface-layer2);
  border: 1px solid var(--analog-surface-layer2);
}

.analog-input--s {
  height: var(--analog-component-size-s);
}

.analog-input--m {
  height: var(--analog-component-size-m);
}

.analog-input--l {
  height: var(--analog-component-size-l);
}

.analog-input__input {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0 8px;
  background-color: var(--analog-text-field-background2);
  color: var(--analog-text-primary);
  border: 0;
  font-family: inherit;
  cursor: text;
  font-weight: 400;
}

.analog-input--light .analog-input__input {
  background-color: var(--analog-text-field-background2);
}

.analog-input--dark .analog-input__input {
  background-color: var(--analog-surface-layer2);
}

.analog-input--light .analog-input__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--analog-text-field-background2) inset;
  -webkit-text-fill-color: var(--analog-text-primary);
}

.analog-input--dark .analog-input__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--analog-surface-layer2) inset;
  -webkit-text-fill-color: var(--analog-text-primary);
}

.analog-input__input::placeholder {
  color: var(--analog-text-placeholder);
}

.analog-input:hover {
  border-color: var(--analog-border-primary-inverse);
}

.analog-input__adornment {
  display: flex;
  align-items: center;
  color: var(--analog-text-secondary);
}

.analog-input__startAdornment {
  padding-left: 12px;
}

.analog-input__endAdornment {
  padding-right: 4px;
}

.analog-input__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.analog-input--s .analog-input__spinner {
  width: 24px;
  height: 24px;
}

.analog-input--m .analog-input__spinner {
  width: 32px;
  height: 32px;
}

.analog-input--l .analog-input__spinner {
  width: 40px;
  height: 40px;
}

.analog-input.analog-input--focused,
.analog-input:focus-within {
  border-color: var(--analog-active-primary);
}

.analog-input--invalid {
  border-color: var(--analog-support-error);
}

.analog-input--disabled {
  pointer-events: none;
  user-select: none;
  background-color: var(--analog-text-field-disabled2);
  border-color: var(--analog-disabled1);
}

.analog-input--disabled .analog-input__input,
.analog-input--disabled .analog-input__input::placeholder {
  color: var(--analog-disabled1);
}

.analog-input--disabled .analog-input__input {
  background-color: var(--analog-text-field-disabled2);
}

.analog-input__input::-webkit-outer-spin-button,
.analog-input__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.analog-input__input[type="number"] {
  -moz-appearance: textfield;
}
