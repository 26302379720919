.analog-slider {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
}

.analog-slider .analog-dropdown.analog-popover {
  width: 30px;
  height: 30px;
}

.analog-slider__track {
  background-color: var(--analog-interactive-primary);
  height: 6px;
  border-radius: 6px;
}

.analog-slider__track-1 {
  background-color: var(--analog-surface-layer2);
  height: 6px;
  border-radius: 6px;
}

.analog-slider__thumb {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: var(--analog-interactive-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
}

.analog-slider__thumbInner {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--analog-white);
}

.analog-slider__thumbValue {
  position: absolute;
  top: 0;
  left: 50%;
  height: 30px;
  transform: translate(-50%, -36px);
  background-color: var(--analog-interactive-primary);
  color: var(--analog-text-primary);
  padding: 4px 12px;
  border-radius: 8px;
  white-space: nowrap;
}

.analog-slider__thumbArrow {
  width: 8px;
  height: 8px;
  background-color: var(--analog-interactive-primary);
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
