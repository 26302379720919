@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  display: flex;
  padding: 0px 32px 0 0px;
  height: 100%;
}

.page {
  width: calc(100% - var(--menubar-expanded-width) - var(--margin));
  margin-left: var(--margin);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease; // add transition for smooth resize

  @include tablet {
    width: calc(100% - var(--menubar-collapsed-width) - var(--margin));
    height: calc(100% - #{$menubar-height});
  }
}
