@import "src/styles/variables";
@import "src/styles/mixins";

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 15px;
  background-color: $menu-color;
  margin-bottom: 10px;
  border-radius: 10px;
}

.searchFilter {
  display: flex;
}

.search {
  width: 228px;
}

.button {
  font-size: 14px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  font-weight: 600;

  @include tablet {
    width: 42px;
  }
}

.athleteCardWrapper {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #f3f3f6;
  box-sizing: border-box;
}

.contentWrapper {
  background: none;
  padding: 0;
}

.athleteCell {
  display: flex;
  align-items: center;
}
