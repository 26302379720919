@import "src/styles/variables";
@import "src/styles/mixins";

.columnHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.infoIcon {
  height: 15px;
  width: 15px;
  cursor: pointer;
  fill: $primary-input-text-color;
}

.tooltipContent {
  background-color: $tooltip-bg-color;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 8px;

  span:last-child {
    font-weight: bold;
  }
}
.commentTooltipContent {
  background-color: $tooltip-bg-color;
  color: $tooltip-text-color;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid $tooltip-border-color;
  font-size: 10px;
  line-height: 150%;
  font-weight: 500;
}

.popupTooltipContent {
  background-color: $tooltip-bg-color;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 8px;
  width: 370px;
  font-size: 8px;
  font-weight: 400;
}

.popupTooltipContent h3 {
  margin-bottom: 10px;
}

.popupTooltipContent ul {
  list-style-type: disc;
  padding-left: 10px;
}

.popupTooltipContent li {
  margin-bottom: 10px;

  strong {
    font-weight: 700;
  }
}

.commentsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;

  margin: 10px 0px;
}
