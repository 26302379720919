@import "src/styles/variables";
@import "src/styles/mixins";

.countdown {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 200px;
  height: 200px;

  .text {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: $accent-primary;
  }

  .countdownCircle {
    transform: rotate(0deg);
    width: 100%;
    height: 100%;
  }

  .circleBg {
    fill: none;
    stroke: rgba($accent-primary, 0.3);
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke: $accent-primary;
    stroke-width: 3.8;
    transition: stroke-dasharray 0.1s linear;
  }

  .label {
    position: absolute;
    font-size: 1.5rem;
    color: #333;
  }
}
