.analog-calendar {
  box-sizing: border-box;
  width: 312px;
  height: 364px;
  padding: 16px;
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--analog-surface-layer2);
  overflow-y: auto;
}

.analog-calendar * {
  box-sizing: border-box;
}

.analog-calendar__periodControl {
  display: flex;
  align-items: center;
  margin-block-end: 12px;
}

.analog-calendar__monthButtons {
  display: flex;
  align-items: center;
  margin-inline-start: auto;
}

.analog-calendar__periodControlButton.analog-button {
  padding: 0 12px;
  color: var(--analog-text-primary);
}

.analog-calendar__dates {
  display: flex;
  flex-wrap: wrap;
}

.analog-calendar__years-wrapper {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.analog-calendar__weekDays {
  display: flex;
  align-items: center;
  cursor: default;
}

.analog-calendar__weekDay {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.analog-calendar__dateButton {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  transition: 0.15s ease-out;
  outline: none;
  user-select: none;
  background-color: transparent;
  font-size: inherit;
  line-height: inherit;
}

.analog-calendar__day {
  width: 40px;
}

.analog-calendar__month {
  width: 50%;
}

.analog-calendar__year {
  width: 33.33%;
}

.analog-calendar__dateButton--current {
  color: var(--analog-text-primary);
}

.analog-calendar__dateButton--previous,
.analog-calendar__dateButton--next {
  color: var(--analog-text-secondary);
}

.analog-calendar__dateButton--today {
  color: var(--analog-text-primary);
  background-color: var(--analog-surface-layer1);
}

.analog-calendar__dateButton--selected {
  background-color: var(--analog-interactive-primary);
  color: var(--analog-text-primary);
}

.analog-calendar__dateButton--current:focus-visible,
.analog-calendar__dateButton--current:hover {
  background-color: var(--analog-interactive-primary-hover);
  color: var(--analog-text-primary);
}

.analog-calendar__dateButton--previous:focus-visible,
.analog-calendar__dateButton--previous:hover,
.analog-calendar__dateButton--next:focus-visible,
.analog-calendar__dateButton--next:hover {
  background-color: var(--analog-surface-layer1);
  color: var(--analog-text-secondary);
}

.analog-calendar__dateButton--today:focus-visible,
.analog-calendar__dateButton--today:hover {
  background-color: var(--analog-interactive-primary-hover);
  color: var(--analog-text-primary);
}

.analog-calendar__dateButton--selected:focus-visible,
.analog-calendar__dateButton--selected:hover {
  background-color: var(--analog-interactive-primary-active);
  color: var(--analog-text-primary);
}

.analog-calendar__dateButton--range.analog-calendar__dateButton--selected {
  border-radius: 0;
}

.analog-calendar__dateButton--rangeStart,
.analog-calendar__dateButton--rangeEnd {
  background-color: var(--analog-interactive-primary);
  color: var(--analog-white);
}

.analog-calendar__dateButton--rangeStart:hover,
.analog-calendar__dateButton--rangeStart:focus-visible,
.analog-calendar__dateButton--rangeEnd:hover,
.analog-calendar__dateButton--rangeEnd:focus-visible {
  background-color: var(--analog-interactive-primary-hover);
  color: var(--analog-white);
}

.analog-calendar__dateButton--rangeStart.analog-calendar__dateButton--selected {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.analog-calendar__dateButton--rangeEnd.analog-calendar__dateButton--selected {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.analog-calendar__dateButton--disabled {
  color: var(--analog-disabled2);
  pointer-events: none;
}
