.analog-message--neutral {
  color: var(--analog-text-primary);
}

.analog-message--info {
  color: var(--analog-support-info);
}

.analog-support-info-light {
  color: var(--analog-support-info-light);
}

.analog-message--success {
  color: var(--analog-support-success);
}

.analog-message--success-light {
  color: var(--analog-support-success-light);
}

.analog-message--warning {
  color: var(--analog-support-warning);
}

.analog-message--warning-light {
  color: var(--analog-support-warning-light);
}

.analog-message--error {
  color: var(--analog-support-error);
}

.analog-message--error-light {
  color: var(--analog-support-error-light);
}
