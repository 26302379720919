@import "src/styles/variables";
@import "src/styles/mixins";

/* GroupCard.css */
.groupInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @include tablet {
    flex-direction: column;
  }
}

.groupName {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.groupMembers {
  display: flex;
  align-items: center;
}

.groupCard {
  max-width: 200px;
}

.memberImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: -10px;
  background-color: white;
}

.memberRemainder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid rgba($color: $accent-primary, $alpha: 0.5);
  margin-right: 10px;
  background-color: rgba($color: $accent-primary, $alpha: 0.5);
  color: white;
  font-weight: bold;
}
