.analog-dialog__overlay {
  background: var(--analog-overlay-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: var(--layer-overlay);
  opacity: 1;
  animation: appear 0.1s ease-in;
}

.analog-dialog__content {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 480px;
  flex-shrink: 0;
  gap: 24px;
  padding: 24px;
  max-width: calc(100% - 48px);
  max-height: calc(100% - 48px);
  background-color: var(--analog-surface-layer1);
  border-radius: 8px;
  overflow-y: auto;
  cursor: default;
  transition: 0.1s ease-in-out;
}

.analog-dialog__content--fullScreen {
  width: 100%;
  height: 100%;
}

.analog-dialog__header {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding-right: 48px;
}

.analog-dialog__title {
  margin: 0;
  color: var(--analog-text-primary);
  padding-right: 96px;
}

.analog-dialog__header .analog-dialog__title {
  padding-right: 0;
}

.analog-dialog__subtitle {
  margin: 16px 0 0 0;
  color: var(--analog-text-secondary);
}

.analog-dialog__header-actions {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.analog-dialog__footer {
  padding-top: 16px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.analog-dialog__footer-buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.analog-dialog__body {
  overflow: auto;
  flex: 1 auto;
  margin-inline-end: -24px;
  padding-inline-end: 24px;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
