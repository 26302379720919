@import "src/styles/variables";
@import "src/styles/mixins";

.header {
  font-size: 25px;
  font-weight: 700;
  margin-left: 1rem;
  margin-bottom: 0 !important;
}

.exportDropdownAthlete {
  display: flex;
  justify-content: flex-start;
  background-color: $menu-color;
  padding: 10px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  align-items: center;
  height: 4rem;

  .tableBack {
    width: 5rem;
    align-self: center;
    color: $accent-primary;
    margin-left: 1rem;
    cursor: pointer;
    font-weight: 600;

    .backTick {
      fill: $accent-primary;
    }
  }
}

.exportDropdown {
  display: flex;
  justify-content: flex-start;
  background-color: $menu-color;
  padding: 10px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  align-items: center;

  .tableBack {
    width: 5rem;
    align-self: center;
    color: $accent-primary;
    margin-left: 1rem;
    cursor: pointer;
    // font-weight: bolder;
  }

  .separator {
    border-left: 0.5x solid $popup-color-dropdown;
    margin: 0 20px;
    width: 1px;
    height: 10vh !important;
    align-content: center;
    text-align: center;
  }

  .table {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    grid-gap: 1rem 0.313rem;
    margin-left: 8px;

    .tableHeading {
      display: flex;
      flex-direction: column;
      color: $secondary-text-color;
      font-size: 14px;

      .date {
        font-weight: 600;
      }
    }

    .tableData {
      color: $primary-text-color;
      font-size: 14px;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      text-wrap: nowrap;
    }
  }

  .markRecovered {
    background: white;
    color: black;
    width: fit-content;
    font-size: 12px;
    font-weight: 450;
    padding: 5px 10px 5px 10px;
    margin-left: -1px;
    margin-right: 1rem;
    margin-top: 0.6rem;
    cursor: pointer;
  }

  // .separator {
  //     width: 1px;
  //     height: 50px;
  //     background-color: #fff;
  //     margin: 0 20px;
  // }

  .recoveredEditSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // padding: 1rem;
    width: 30%;
    font-size: 14px;
    color: $secondary-text-color;
  }
}

.editName {
  display: flex;
  align-self: center;
  margin-right: 1rem;
  color: #8c93a6;
  font-weight: 600;
  cursor: pointer;

  .editIcon {
    margin-left: 6px;
    color: #8c93a6;
    fill: $accent-primary;
  }
}

.date {
  color: $accent-primary;
  font-weight: 600;
}

.healthTabsWrapper {
  display: block;
}

.customTooltip {
  background-color: $card-color;
  color: $primary-text-color;
  padding: 0.5rem;
  height: 30%;
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}
