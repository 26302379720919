.analog-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  flex-shrink: 0;
}

.analog-checkbox__input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.analog-checkbox__check {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid var(--analog-input-border);
  transition: ease-in 0.12s;
}

.analog-checkbox--s .analog-checkbox__check {
  width: 18px;
  height: 18px;
}

.analog-checkbox--m .analog-checkbox__check {
  width: 24px;
  height: 24px;
}

.analog-checkbox__mark {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  color: var(--analog-white);
}

.analog-checkbox:focus-within .analog-checkbox__check,
.analog-checkbox__input:hover + .analog-checkbox__check {
  border-color: var(--analog-focus-primary);
}

.analog-checkbox__text {
  display: inline-flex;
  align-items: center;
  width: auto;
  transition: 0.1s ease-in-out;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
  color: var(--analog-text-primary);
}

.analog-checkbox:active .analog-checkbox__check {
  border-color: var(--analog-active-primary);
}

.analog-checkbox.analog-checkbox--indeterminate .analog-checkbox__check,
.analog-checkbox__input:checked + .analog-checkbox__check {
  background-color: var(--analog-interactive-primary);
  border-color: var(--analog-interactive-primary);
}

.analog-checkbox--indeterminate .analog-checkbox__check .analog-checkbox__mark,
.analog-checkbox__input:checked
  + .analog-checkbox__check
  .analog-checkbox__mark {
  opacity: 1;
}

/* prettier-ignore */
.analog-checkbox--indeterminate:focus-within .analog-checkbox__input:checked + .analog-checkbox__check,
.analog-checkbox:focus-within .analog-checkbox__input:checked + .analog-checkbox__check,
.analog-checkbox--indeterminate:hover .analog-checkbox__check,
.analog-checkbox__input:checked:hover + .analog-checkbox__check {
  background-color: var(--analog-interactive-primary-hover);
  border-color: var(--analog-interactive-primary-hover);
}

.analog-checkbox:active
  .analog-checkbox__input:checked
  + .analog-checkbox__check {
  background-color: var(--analog-interactive-primary-active);
  border-color: var(--analog-interactive-primary-active);
}

.analog-checkbox--disabled {
  pointer-events: none;
  cursor: default;
}

.analog-checkbox--disabled .analog-checkbox__check,
.analog-checkbox--disabled
  .analog-checkbox__input:checked
  + .analog-checkbox__check {
  border-color: var(--analog-disabled2);
  background-color: var(--analog-disabled1);
}

.analog-checkbox--indeterminate.analog-checkbox--disabled
  .analog-checkbox__check {
  border-color: var(--analog-disabled2);
  background-color: var(--analog-disabled1);
}

.analog-checkbox--disabled .analog-checkbox__mark {
  color: var(--analog-text-field-disabled2);
}

.analog-checkbox--disabled .analog-checkbox__text {
  color: var(--analog-text-field-disabled2);
}
