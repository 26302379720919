@import "src/styles/variables";

.wrapper {
  width: 100%;
  height: 8px;
  border-radius: 32px;
  background-color: $menu-color;

  & + & {
    margin-top: 6px;
  }
}

.bar {
  height: 100%;
  border-radius: 32px;
}
