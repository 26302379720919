@import "src/styles/mixins";

.content {
  height: 700px;
  background-color: $chart-bg-color;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.selectors {
  display: flex;
  gap: 1rem;
}

.bold {
  font-weight: bold;
  text-transform: capitalize;
}

.bigTitle {
  font-size: 18px;
  flex-grow: 3;
}
