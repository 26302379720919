@import "src/styles/variables";

.heading {
  display: flex;
  align-items: center;
  color: $primary-gray-900;
}

.icon {
  display: inline-flex;
  margin-inline-end: 0.75rem;
}
