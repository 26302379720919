@import "src/styles/mixins";

.button {
  @include reset-button;
  padding: 9px 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  border: 1px solid $card-color;
  background: rgba($card-color, 0.5);
}
