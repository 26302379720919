/* stylelint-disable selector-class-pattern */
@import "src/styles/typography";

.title {
  margin: 0;
}

.xxl {
  @include title-xxl;
}

.xl {
  @include title-xl;
}

.l {
  @include title-l;
}

.bold {
  @include font-bold;
}

.medium {
  @include font-medium;
}

.regular {
  @include font-regular;
}
