@import "./colors";

$menubar-height: 26px;
$menubar-width: 250px;
$side-tabs-width: 258px;
$side-tabs-width-tablet: 162px;
$bounded-charts-height: 240px;
$ranking-width: 264px;

// account sign  in page
$account-input-wrapper-color: #eaeff5;
$account-background-size: fill;
$account-button-color: #f48371;
$account-default-btn-color: #c1d2fa;
$account-type-active: #7a5af8;

// sign in page
$sign-in-bg-color: #ebefff;
$sign-in-input-color: #f5f7ff;
$sign-in-text-color: #104c89;
$sign-in-input-label-color: #3773c0;
$sign-in-textfield-bg-color: #ffffff;
$sign-in-textfield-border-color: #3773c0;
$sign-in-textfield-placeholder: #8cb3ed;
$sign-in-button-color: #f48371;
$sign-in-button-disabled-bg-color: #efb9b8;
$sign-in-button-disabled-text-color: #f3f3f6;
$sign-in-remember-me-label-color: #3773c0;
$sign-in-error-color: #981c1b;

// forgot password page
$forgot-password-text-color: #104c89;
$forgot-password-bg-color: #c1d2fa;

// home page
$account-btn-bg: rgba(16, 76, 137, 0.2);

// Add comparison list
$add-comparison-list-bg: #e0acf7;
$add-comparison-text-color: #7b2ca5;

// Chart CSS
$chart-bg-color: #f5f7ff;
$chart-border-color: #a7b7c2;

// Search Cyclist
$search-cyclist-bg: linear-gradient(
  92.29deg,
  rgb(24, 114, 205) -263.03%,
  rgb(12, 60, 109) 104.3%
);
$menu-item-bg-color: #ebefff;
$selected-menu-item: #104c89;
$selected-sub-menu-item: #104c89;

$bottom-border-color: #a28bff;
$list-edit-btn-bg: #7a5af8;
$border-color: #a7b8c2;
$input-border-color: #bcc9d3;
$panel-color: #f5f7ff;
$popup-color: #f5f7ff;
$card-color: #d8e2fd;
$menu-color: #f5f7ff;

$hover-color: #c0c9d4;
$performance-ranking-color: #f0f4f8;
$dropdown-color: #f0f4f8;
$dropdown-hover: linear-gradient(91.92deg, #e8eef3 -3.89%, #f4f8fc 98.68%),
  linear-gradient(91.92deg, #ffffff -3.89%, #f8fafb 98.68%);
$calendar-bg-color: #104c89;
$dropdown-text-color: #7a5af8;
$main-bg-color: #ffffff;
$gray-bg-color: #b0b8c5;

$primary-text-color: #303a47;
$secondary-text-color: #3773c0;

$accent-primary: #104c89;
$accent-secondary: #ffa07a;

$error-text: #ff6b85;

$upper-value-color: #ff88a2;

// menstrual cards
$border-radius: (
  extra-small: 4px,
  small: 8px,
  medium: 16px,
  large: 24px,
);

$card-background-color: #fcfcfd;
$card-border-color: #e4e7ec;
$card-border-radius: map-get($border-radius, medium);

$menstrual-cycle-calendar-head-cell-size: 2.25rem;

$menstrual-cycle-calendar-cell-width: 2.75rem;
$menstrual-cycle-calendar-cell-height: 2.625rem;
$menstrual-cycle-calendar-cell-padding: 0.125rem;

$menstrual-cycle-calendar-cell-border-width: 1px;
$menstrual-cycle-calendar-cell-border-color: $card-border-color;
$menstrual-cycle-calendar-cell-border-radius: 0.5rem;

$menstrual-cycle-calendar-background-color: #f4f3ff;
$menstrual-cycle-calendar-accent-color: #6938ef;

// skeleton
$primary-gray-100: #f2f4f7;
$primary-gray-200: $card-border-color;

// dropdown bg
$dropdown-bg: #d8e2fd;

// athlete card
$athlete-card-color: #104c89;
$athlete-card-info-color: #104c89;
$athlete-card-group-color: #3c91e7;
$athlete-card-bg-color: #d1ddf4;
$athlete-avatar-border-color: #c1d2fa;

$background-text-field: #b0b8c5;
$radar-selector-button-active: #6938ef;

// Team Power Profile
$dropdown-border-color: #a9c2f4;
$tooltip-font-color: #104c89;
$dropdown-bg-color: #ffffff;

// Change password
$change-password-input-color: #ffffff;

$pop-up-bg-color: #1f5fa6;

// chat bot
$chat-suggestion-bg: #d8e2fd;
$chat-suggestion-color: #104c89;
$chat-text-color: #104c89;
$chat-textfield-bg: #ebefff;
$chat-textfield-placeholder: #3773c0;
$chat-bubble-system-bg: #f48371;
$chat-bubble-user-bg: #d8e2fd;
$chat-input-wrapper-color: #f5f7ff;
$chat-body-bg: #ebefff;
$chat-body-icon-bg: #262b36;
$chat-window-border-color: #c1d2fa;
$chat-window-header-bg: #f5f7ff;
$chat-feedback-color: #6b7282;
$chat-dot-icon-bg: #1b212c;

// Calendar
$pop-up-calendar-bg-color: #ebefff;
$pop-up-calendar-day-color: #104c89;
$pop-up-calendar-text-field-label-color: #104c89;
$pop-up-calendar-selected-day-color: #353943;
$pop-up-calendar-selected-day-bg-color: #f48371;
$pop-up-calendar-disabled-day-color: rgba(55, 115, 192, 0.2);
$pop-up-calendar-week-color: #6f7890;

// icon colos
$icon-color: #104c89;

// performance management chart tooltip
$performance-management-tooltip-text: #104c89;
$performance-management-tooltip-title: #8c93a6;
$tsbLow: #ff0000;
$tsbHigh: #ff8c00;

:root {
  --menubar-collapsed-width: 74px; // collapsed width
  --menubar-expanded-width: 200px; // expanded width
  --margin: 20px;
}

// menubar header
$menubar-gradient: linear-gradient(
  135deg,
  #4d8fd7 10%,
  #e56765 55%,
  #f7ca70 100%
);
$unselected-menu-item: #8cb3ed;

// tooltip color
$tooltip-bg-color: #ffffff;
$tooltip-text-color: #104c89;
$tooltip-border-color: #71a2f4;

$clear-all-color: #8c93a6;

// all comment
$warning: rgba(113, 162, 244, 0.5);
$submitAllButtonColor: #f3f3f6;
$previousDisabledBackgroundColor: #c1d2fa;
$disabledBackgroundColor: rgba(165, 220, 185, 0.5);
$previousDisabledBorder: 1px solid #71a2f4;
$previousDisabledColor: #3773c0;
$rectangularDivBackgroundColor: #c1d2fa;

// coach coimment popup
$detail-label-color: #104c89;
$detail-value-color: #353943;
$detail-odd-color: #f5f7ff;
$detail-even-color: #d8e2fd;

$primary-input-text-color: #64a0fa;
$comment-button-disabled-bg-color: #8c93a6;

$primary-card-text-color: #104c89;
$card-background-color: #ebefff;
$card-details-bg-color: #f5f7ff;
$card-item-title: #104c89;
$card-item-unit: #8cb3ed;
$card-item-value: #353943;
$card-corner-color: rgba(0, 93, 178, 0.7);
$card-vertical-dots-color: #ffffff;

$popup-bg-dropdown: transparent;
$popup-border-color: #d8e2fd;
$popup-drop-items-color: #f3f3f6;
$popup-bgcolor-dropdown: #008b32;
$popup-hover-drop: #f5f7ff;
$drop-bg: #f5f7ff;
$drop-options-color: rgb(111, 43, 43);
$popup-color-dropdown: #3773c0;
$popup-select-drop: #3773c0;

$react-select-focus-color: #fff;
$react-select-selected-text-color: #fff;
$react-select-surface-color: #ebefff;
$react-select-surface-hover: #3773c0;
$react-select-option-color: #3773c0;

$close-button-fill-color: #104c89;
$dropdown-selected-value-color: #104c89;
$medical-table-icon-color: #1f5fa6;

// chat feedback colors
$chat-feedback-btn-fill-color: #fff;
$chat-feedback-btn-stroke-color: #565b68;
