@import "src/styles/variables";
@import "src/styles/mixins";

// ToDo: lines 4-17 from session-expired-popup.module.scss - duplicates. Need to refactor.
.popupBox {
  position: fixed;
  z-index: 20;
  background: rgba($pop-up-bg-color, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px 10px;
}

.athleteCardWrapper {
  width: 100%;
  margin-top: 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #f3f3f6;
  box-sizing: border-box;
}

.calendar {
  background-color: $pop-up-calendar-bg-color;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.box {
  position: relative;
  margin: 0 auto;
  height: auto;
  background: $popup-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: auto;
}

.inputBox {
  width: 75vw;
  padding: 32px;

  @include tablet {
    width: 95vw;
  }

  @include tablet-portrait {
    width: 62vw;
  }
}

.closeButton {
  cursor: pointer;
  margin-left: auto;
  fill: $accent-primary;
}

.footer {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.lastEntry {
  font-size: 12px;
}

.addButton {
  width: 183px;
  padding: 12px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: #f3f3f6;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.header {
  display: flex;
  justify-content: space-between;
}

.errorInput {
  border: 1px solid red;
  background-color: rgba($error-text, 0.2);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.anthroData {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  position: absolute;
  margin: 1px 14px 0;
}

.ellipse {
  background-color: $pop-up-calendar-selected-day-bg-color;
}
