.analog-skeleton {
  background-color: var(--analog-surface-layer4);
  animation: skeleton-pulse 1.8s ease-in-out 0.5s infinite;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
