@import "src/styles/mixins";

.content {
  @include panel;
  height: 512px;
  padding: 16px;
  margin-bottom: 16px;
}

.addBorder {
  border: 1px solid $border-color;
}

.lineChart {
  composes: content;
  height: 350px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.selectors {
  display: flex;
  gap: 1rem;
}

.stub {
  @include bounded-chart-stub;
}

.bold {
  font-weight: bold;
  text-transform: capitalize;
}

.bigTitle {
  font-size: 18px;
  flex-grow: 3;
}
