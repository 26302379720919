@import "src/styles/variables";
@import "src/styles/mixins";

.wrapper {
  display: flex;
  padding: 0px 32px 0 0px;
  height: 100%;
}

.page {
  width: calc(100% - var(--menubar-expanded-width) - var(--margin));
  margin-left: var(--margin);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease; // add transition for smooth resize
  box-sizing: border-box;

  @include tablet {
    width: calc(100% - var(--menubar-collapsed-width) - var(--margin));
    height: calc(100% - #{$menubar-height});
  }
}

.pageContent {
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 32px);
  box-sizing: border-box;

  @include tablet {
    height: calc(100vh - #{$menubar-height} - 32px);
  }
}
