@import "src/styles/variables";
@import "src/styles/mixins";

.charts {
  $margin: 20px;
  width: calc(100% - #{$side-tabs-width + $margin});
  margin-left: $margin;

  @include tablet-portrait {
    $margin-tablet: 15px;
    width: calc(100% - #{$side-tabs-width-tablet + $margin-tablet});
    margin-left: $margin-tablet;
  }
}

.component + .component {
  margin-top: 32px;
}
