@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.analog-toast-container--show {
  animation: show 0.1s ease-out both;
}

.analog-toast-container--hide {
  animation: hide 0.1s ease-in both;
}

.analog-toast {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 480px;
  flex-shrink: 0;
  background-color: var(--analog-surface-layer4);
  z-index: var(--layer-alerts);
  border-radius: 8px;
  overflow: hidden;
}

.analog-toast__icon {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 72px;
  flex-shrink: 0;
  border-right: 1px solid var(--analog-border-primary-inverse);
}

.analog-toast--info .analog-toast__icon {
  color: var(--analog-support-info);
}

.analog-toast--success .analog-toast__icon {
  color: var(--analog-support-success);
}

.analog-toast--warning .analog-toast__icon {
  color: var(--analog-support-warning);
}

.analog-toast--error .analog-toast__icon {
  color: var(--analog-support-error);
}

.analog-toast__details {
  box-sizing: border-box;
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.analog-toast__details-title {
  padding-right: 32px;
  color: var(--analog-text-primary);
}

.analog-toast__details-message {
  color: var(--analog-text-secondary);
}

.analog-toast__actions {
  position: absolute;
  top: 14px;
  right: 16px;
  box-sizing: border-box;
}

.analog-toast-container {
  padding: 0;
  position: fixed;
  top: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: var(--layer-alerts);
}
