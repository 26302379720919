@import "src/styles/variables";
@import "src/styles/mixins";

.dropzoneStyle {
  background-color: $card-color;
  border: "2px dashed #007BFF";
  border-radius: "5px";
  padding: "20px";
  text-align: "center";
  // height: 105px;
  cursor: pointer;
  border: dotted;
  border-width: 2px;
  border-radius: 10px;
  border-color: $accent-primary;
  margin: 10px;
}

.file {
  color: $accent-primary;
}

.newNote {
  background: transparent;
  border: 1px solid #008b32;
  color: #008b32;
  font-size: 14px;
  width: fit-content;
  padding: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.dragFilesBox {
  height: 100px;
  align-content: center;
  text-align: -webkit-center;
  font-size: 20px;
  margin: 5px;
}
