.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 auto;
  gap: 4px;
}

.emptyStateImage {
}

.emptyStateText {
  color: var(--analog-text-placeholder);
}
