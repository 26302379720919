.analog-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.analog-pagination__pages {
  display: flex;
  align-items: center;
  gap: 4px;
}

.analog-pagination__button {
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 28px;
  height: 28px;
  border-radius: 14px;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.15s ease-out;
  user-select: none;
  background-color: transparent;
  color: var(--analog-text-primary);
}

.analog-pagination__buttonDots {
  pointer-events: none;
}

.analog-pagination__button--active,
.analog-pagination__button:focus,
.analog-pagination__button:hover {
  background-color: var(--analog-surface-layer4);
  color: var(--analog-text-primary);
}

.analog-pagination__button:active {
  background-color: var(--analog-surface-layer3);
}

.analog-pagination__button[disabled] {
  pointer-events: none;
  color: var(--analog-gray-500);
}

.analog-pagination__dropdown {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.analog-pagination__dropdown-anchorIcon {
  transition: transform 0.175s ease-in;
}

.analog-pagination__dropdown-anchorIcon--rotate {
  transform: rotate(180deg);
}

.analog-pagination__dropdown-label {
  user-select: none;
  color: var(--analog-text-primary);
}

.analog-pagination__count-label {
  user-select: none;
  color: var(--analog-text-primary);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;
}

.analog-pagination__count-label span {
  color: var(--analog-text-primary);
}

.analog-pagination__left {
  display: flex;
  align-items: center;
  gap: 12px;
}
