@import "src/styles/variables";
@import "src/styles/mixins";

/* GroupCard.css */
.groupCard {
  background-color: $athlete-card-bg-color;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $accent-primary;
  padding: 20px 10px 10px 20px;
  width: 300px;
  height: 180px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background: $hover-color;
    cursor: pointer;
  }
}

.groupContent {
  margin-bottom: 20px;
}

.groupName {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.groupDescription {
  font-size: 14px;
  margin-top: 10px;
  opacity: 0.75;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.groupMembers {
  display: flex;
  align-items: center;
}

.memberImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  margin-right: -10px;
  background-color: white;
}

.memberRemainder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid rgba($color: $accent-primary, $alpha: 0.5);
  margin-right: 10px;
  background-color: rgba($color: $accent-primary, $alpha: 0.5);
  color: white;
  font-weight: bold;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  & * {
    width: 30px;
    display: flex;
    align-items: center;
  }

  .editButtonIcon {
    fill: $accent-primary;
  }
  .deleteButtonIcon {
    fill: red;
  }
}
