@import "src/styles/variables";

.content {
  height: 440px;
  background-color: $chart-bg-color;
  padding: 16px;
  border-radius: 8px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.bigTitle {
  font-size: 18px;
  flex-grow: 1;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.globalTooltip {
  position: relative;
  top: 10px;
  display: flex;
  flex-direction: row;
  color: $performance-management-tooltip-title;
  gap: 1rem;
  font-size: 14px;

  .tooltipItem {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    color: $performance-management-tooltip-title;
    &::after {
      content: "|";
    }
    &:last-child::after {
      content: "";
    }
  }

  .bold {
    font-weight: bold;
    color: $performance-management-tooltip-text;
  }

  .tsbLow {
    color: $tsbLow;
  }

  .tsbHigh {
    color: $tsbHigh;
  }
}
