.analog-tabs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.analog-tabs__tabs {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.analog-tabs__tabs-items {
  display: flex;
  align-items: center;
}

.analog-tabs__tabs-divider {
  width: 100%;
  height: 1px;
  background-color: var(--analog-border-primary);
}

.analog-tabs__active-underline {
  position: absolute;
  inset-block-end: 0;
  height: 2px;
  width: 100px;
  border-radius: 1px;
  background-color: var(--analog-interactive-primary);
  transition: 0.3s;
}

.analog-tabs__tab-item {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  flex-shrink: 0;
  padding: 8px 16px;
  border-radius: 8px;
  border: 0;
  outline: none;
  background-color: transparent;
  color: var(--analog-text-primary);
  cursor: pointer;
  user-select: none;
  transition: 0.15s ease-out;
}

.analog-tabs__tab-item:focus,
.analog-tabs__tab-item:hover {
  background-color: var(--analog-surface-layer1);
}

.analog-tabs__tab-item--active {
  color: var(--analog-text-primary);
}

.analog-tabs__tab-item[disabled] {
  color: var(--analog-text-field-disabled2);
  pointer-events: none;
}

.analog-tabs__tab-item-tag {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analog-tabs__tab-item-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--analog-text-primary);
}

.analog-tabs__tab-tooltipIcon {
  color: var(--analog-text-primary);
}

.analog-tabs__tab-item-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
