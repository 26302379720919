@import "src/styles/variables";
@import "src/styles/mixins";

.popupBox {
  position: fixed;
  z-index: 200;
  background: rgba($pop-up-bg-color, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.box {
  background: $popup-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: auto;
  position: relative;
  padding: 32px;
  flex-direction: row;
  gap: 20px;
}

.popupDataContainer {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  color: $primary-text-color;
  // border-bottom: 1px solid rgba($hover-color, 0.5);

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .closeButton {
    cursor: pointer;
    fill: $close-button-fill-color;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // width: 60%;
}

.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  label {
    width: 150px;
    display: block;
    font-size: 14px;
    margin-right: 10px;
  }

  select,
  textarea,
  input[type="date"] {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    // border: 1px solid #ccc;
    background: $popup-bg-dropdown;
    color: $dropdown-selected-value-color;
    border-color: $card-color;
    width: 100%;
    border-radius: 10px;
    font-family: inherit;
  }

  textarea {
    height: 100px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }

  textarea:focus-visible {
    outline: none;
  }
}

.select:focus-visible {
  border: 0;
}

.selectBox {
  border: 0 !important;

  .react-select__single-value {
    font-size: 14px;
  }
}

.selectBox:focus-visible {
  outline: none;
  border: 0;
}

.saveButton {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  align-self: flex-end;
}

.radiobox {
  // width: 100%;
  label {
    width: 100%;
    text-align: center;
  }
}

.bodyMap {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.addButton {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.tw-toggle {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 999px;
  position: relative;
  background: $menu-item-bg-color;
  border: 1px solid $popup-border-color;
  padding: 4px 0 4px 0;
  color: $secondary-text-color;

  input {
    display: none;
  }

  .toggle {
    cursor: pointer;
    padding: 7.5px;
    font-size: 14px;
    transition:
      background 0.3s,
      color 0.3s;
    position: relative;
    /* Ensure positioning context */
    margin: 0 5px;

    &-yes {
      &:hover {
        color: #007bff;
      }
    }
  }

  .toggle:focus-visible {
    outline: 0;
  }

  .toggle:hover {
    color: $secondary-text-color;
  }

  span {
    position: absolute;
    height: 100%;
    // width: 100%;
    background: transparent;
    transition:
      background 0.3s,
      left 0.3s;
    border-radius: 5px;
    /* Optional: for rounded corners */
    z-index: -1;
    /* Ensure it sits behind labels */
  }

  input[type="radio"]:checked + label {
    border: 1px solid $accent-primary;
    border-radius: 999px;
    background: $accent-primary !important;
    color: $dropdown-color;
    font-weight: 550;
  }
}

.countDown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.customDatePicker {
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  color: $secondary-text-color;
  border: 1px solid $card-color;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 12px;
  font-family: inherit;
  position: relative;
  outline: none;
  cursor: pointer;
  background: $popup-bg-dropdown;
}

.textarea {
  font-family: inherit;
  resize: none;
  overflow-y: auto;
  height: 120px;
  width: 100%;
  background: $change-password-input-color;
  padding: 10px;
  border-radius: 6px;
  font-size: 14px;
  color: $primary-input-text-color;
  border: 1px solid transparent;

  &::placeholder {
    color: $sign-in-textfield-placeholder;
  }

  &:focus-visible {
    outline: none;
  }

  &.errorInput {
    border: 1px solid red;
    background-color: rgba($error-text, 0.2);

    &::placeholder {
      color: $primary-text-color;
    }
  }
}

.datepick {
  width: 100%;
}

.react-select {
  background: none !important;
}
