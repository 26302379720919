.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 14px;
  }
}

.name {
  width: 72px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.bars {
  flex: 1;
}

.values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 32px;
  margin-left: 3px;
  text-align: right;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #8c93a6;
}
