@import "src/styles/variables";
@import "src/styles/mixins";

.current-date-calendar {
  .react-calendar {
    background: $popup-color;
    border: none;
    color: $primary-text-color;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    width: 320px;
  }

  .react-calendar__tile:disabled {
    cursor: auto;
    background: $popup-color;
    opacity: 0.4;
  }

  .react-calendar__month-view__weekdays {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    & abbr {
      text-decoration: none;
    }
  }

  .react-calendar__navigation button,
  .react-calendar__tile {
    color: $primary-text-color;
    border-radius: 4px;
  }

  .react-calendar__navigation__label {
    font-size: 16px;
  }

  .react-calendar__navigation__arrow {
    font-size: 26px;
    line-height: 0;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    font-weight: 700;
  }

  .react-calendar__tile--now {
    background: unset;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: $hover-color;
  }

  .react-calendar__tile--hasActive,
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: rgba($accent-primary, 0.4);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba($primary-text-color, 0.4);
  }

  .react-calendar__navigation button[disabled] {
    background-color: unset;
  }
}
