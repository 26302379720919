@import "src/styles/variables";
@import "src/styles/mixins";

.menubar {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 12px;
  flex-wrap: wrap;
  flex: 1;
  max-width: 600px;
}

.link {
  @include reset-link;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 10px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 20px;

  @include tablet {
    width: auto;
    margin-bottom: 0;
    margin-left: 6px;
    padding: 0;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $secondary-text-color;
    transition: background-color 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s;
  }
}

.activeLink {
  font-weight: 600;
  color: $primary-text-color;

  &::after {
    background-color: $account-button-color;
  }
}

.linkText {
  padding: 8px;
}

.icon {
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  padding: 11px;
  border-radius: 12px;
  fill: $secondary-text-color;

  @include tablet {
    margin-right: 0;
  }
}

.activeLink .icon {
  fill: $primary-text-color;
}
