@import "src/styles/mixins";
@import "src/styles/variables";

.wrapper {
  margin-bottom: 32px;
  border-radius: 12px;
  padding: 16px;
  background-color: $menu-color;
  width: 100%;
}

.topHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgba($accent-primary, 0.1);

  @include tablet {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  @include tablet-portrait {
    max-width: 340px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 30px;
}

.marginTop20 {
  margin-top: 20px;
}

.title {
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
}

.panels {
  display: flex;
  flex-direction: row;

  @include tablet-portrait {
    flex-direction: column;
  }
}

.leftPanel {
  display: flex;
  flex-direction: column;
  flex: 1;

  @include tablet-portrait {
    width: 100%;
    margin-bottom: 16px;
  }
}

.panel + .panel {
  margin-top: 20px;
}

.panelContent {
  width: 100%;
  display: flex;
  border-radius: 12px;
  padding: 0;
}

.legendItems {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.scoreBarLegend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;
  line-height: 32px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0 16px;
  background: $panel-color;
}

.rightPanel {
  margin-left: 20px;
  width: 38%;
  position: relative;

  @include tablet {
    margin-left: 16px;
  }

  @include tablet-portrait {
    width: 100%;
    margin-left: 0;
  }
}

.wellnessPanel {
  height: 100%;
  width: 100%;
  background: $panel-color;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
}

.wellnessContent {
  background: $menu-color;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.barWrapper {
  background: $card-color;
  padding: 12px 16px 20px;
  display: flex;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex-direction: column;
}

.legendIems {
  display: grid;
  grid-column: 1fr;
}

.colorsLegend {
  display: flex;
  text-align: right;
  width: max-content;
  margin-left: auto;
  border-radius: 4px;
  line-height: 16px;
  display: flex;
}

.lastUpdated {
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  font-weight: 300;
}

.total {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  margin-right: 10px;
}

.grey {
  color: #8c93a6;
}

.bigChartSource {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 500;
  position: absolute;
  top: 0px;
  left: 0px;
}

.iconColor {
  fill: $icon-color;
}

.panelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.panelHeaderLeft {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.panelHeaderRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: var(--analog-text-placeholder);
}

.totalScoreValue {
  font-size: 38px;
  font-weight: 700;
  color: var(--analog-text-primary);
  align-self: baseline;
}

.totalScoreMax {
  font-size: 14px;
  font-weight: 400;
  color: var(--analog-text-primary);
  align-self: baseline;
}
