@import "src/styles/variables";
@import "src/styles/mixins";

.inputBlock {
  margin-bottom: 8px;
  font-family: inherit;
}

.label {
  color: $pop-up-calendar-text-field-label-color;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.inputWrapper {
  margin-top: 5px;
}

.reusableInput {
  height: 50px;
  width: 100%;
  background: $change-password-input-color;
  padding: 10px;
  border-radius: 6px;
  font-size: 14px;
  color: $primary-input-text-color;
  border: 1px solid transparent;
  &::placeholder {
    color: $sign-in-textfield-placeholder;
  }
  &.errorInput {
    border: 1px solid red;
    background-color: rgba($error-text, 0.2);
    &::placeholder {
      color: $primary-text-color;
    }
  }
}

.textarea {
  font-family: inherit;
  resize: none;
  overflow-y: auto;
  height: 120px;
  width: 100%;
  background: $change-password-input-color;
  padding: 10px;
  border-radius: 6px;
  font-size: 14px;
  color: $primary-input-text-color;
  border: 1px solid transparent;
  &::placeholder {
    color: $sign-in-textfield-placeholder;
  }
  &:focus-visible {
    outline: none;
  }

  &.errorInput {
    border: 1px solid red;
    background-color: rgba($error-text, 0.2);
    &::placeholder {
      color: $primary-text-color;
    }
  }
}

.charCount {
  display: flex;
  justify-content: flex-end;
  font-size: 9px;
  font-weight: 400;
}

.rangeInfo {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: $primary-input-text-color;
}

/* Remove spinners from number inputs */
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
