@import "src/styles/variables";
@import "src/styles/mixins";

.header {
  font-size: 25px;
  font-weight: 700;
}

.topWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 15px;
  background-color: $menu-color;
  margin-bottom: 10px;
  border-radius: 10px;
}

.searchFilter {
  display: flex;
}

.search {
  width: 228px;
}

.button {
  font-size: 14px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  font-weight: 600;
  margin-left: 0px;
}

.exportDropdown {
  display: flex;
  align-items: center;
  z-index: 12;
}

.contentWrapper {
  padding: 0;
}

.exportButton {
  width: 122px;
  font-size: 14px;
  color: white;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  font-weight: 500;
}

.athleteCell {
  display: flex;
  align-items: center;
}

.chartViewWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $menu-color;
  border-radius: 10px;
  padding: 15px;
}

.infoIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  fill: $accent-primary;
}

.tooltipContent {
  background-color: $tooltip-bg-color;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 8px;

  span:last-child {
    font-weight: bold;
  }
}
