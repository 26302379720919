@import "src/styles/variables";
@import "src/styles/mixins";

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0 15px;
  background-color: $chat-window-header-bg;
  border-radius: 8px 8px 0 0;
  color: $chat-text-color;

  &.minimized {
    cursor: pointer;
    background-color: $chat-bubble-system-bg;
    color: #ffffff;
  }
}

.headerTitle {
  flex-grow: 1;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  font-family: Montserrat;
  margin-right: 10px;
  cursor: pointer;
}

.iconButton {
  cursor: pointer;
  margin-left: 10px;
}

.ecButton {
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}

.verticalDots {
  fill: $icon-color;
  cursor: pointer;
  margin-right: 10px;
  height: 22px;
  width: 24px;
}

.dropdownWrapper {
  position: relative;
}

.dropdown {
  padding: 0 10px;
  border-radius: 10px;
  display: block;
  position: absolute;
  background-color: $card-color;
  min-width: 225px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  right: 70%;
  top: 7px;
  opacity: 1;
  animation: dropDownFadeIn 0.3s ease-in-out;
}

.dropdownMenu {
  display: block;
  text-align: left;
  background-color: $card-color;
  color: $primary-card-text-color;
}
