.statValue {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cardItemVertical {
  display: flex;
  flex-direction: column;
}

.tokenSecondary {
  color: var(--analog-text-secondary);
}

.tokenPrimary {
  color: var(--analog-text-primary);
}

.tokenPlaceholder {
  color: var(--analog-text-placeholder);
}

.statValue {
  display: flex;
  align-items: center;
  gap: 4px;
}
