:root {
  --analog-white: #ffffff;
  --analog-black: #000000;

  --analog-surface-canvas: var(--analog-gray-900);
  --analog-surface-layer1: var(--analog-gray-850);
  --analog-surface-layer2: var(--analog-gray-800);
  --analog-surface-layer3: var(--analog-gray-750);
  --analog-surface-layer4: var(--analog-gray-700);
  --analog-surface-layer5: var(--analog-gray-600);
  --analog-surface-layer6: var(--analog-gray-500);
  --analog-surface-undercanvas: var(--analog-gray-100);
  --analog-surface-accent: var(--analog-gray-300);
  --analog-surface-layer-inverse: var(--analog-gray-100);

  --analog-border-primary: var(--analog-gray-700);
  --analog-border-middle: var(--analog-gray-600);
  --analog-border-primary-inverse: var(--analog-gray-200);

  --analog-input-border: #858e99;
  --analog-input-border-hover: var(--analog-gray-300);

  --analog-text-primary: #f3f3f6;
  --analog-text-secondary: var(--analog-gray-200);
  --analog-text-tertiary: var(--analog-gray-300);
  --analog-text-placeholder: #858e99;
  --analog-text-inverse: #0f0e0e;
  --analog-text-brand: var(--analog-cyan-600);

  --analog-icon-primary: #f3f3f6;
  --analog-icon-secondary: var(--analog-gray-300);
  --analog-icon-tertiary: #858e99;
  --analog-icon-inverse: var(--analog-gray-800);
  --analog-icon-brand: var(--analog-cyan-600);
  --analog-icon-on-accent: #bbeafc;

  --analog-interactive-primary: var(--analog-cyan-600);
  --analog-interactive-primary-hover: var(--analog-cyan-700);
  --analog-interactive-primary-active: var(--analog-cyan-800);

  --analog-interactive-secondary: rgba(var(--analog-cyan-600), 0.1);
  --analog-interactive-secondary-hover: rgba(var(--analog-cyan-700), 0.16);
  --analog-interactive-secondary-active: #00aef03d;

  --analog-interactive-accent: var(--analog-cyan-50);
  --analog-interactive-accent-hover: var(--analog-cyan-100);
  --analog-interactive-accent-active: #bbeafc4b;

  --analog-interactive-danger: var(--analog-support-error);
  --analog-interactive-danger-hover: #b51715;
  --analog-interactive-danger-active: #b51715;

  --analog-interactive-border: var(--analog-cyan-600);
  --analog-interactive-border-hover: var(--analog-cyan-700);
  --analog-interactive-border-active: var(--analog-cyan-800);

  --analog-interactive-text1: #f3f3f6;
  --analog-interactive-text2: var(--analog-cyan-900);
  --analog-interactive-text3: #50c8f5;

  --analog-link-primary: var(--analog-cyan-600);
  --analog-link-visited: var(--analog-cyan-800);
  --analog-link-underline: var(--analog-cyan-600);

  --analog-overlay-primary: rgba(15, 14, 14, 0.7);
  --analog-support-error: #d43025;
  --analog-support-error-light: #fae1e1;
  --analog-support-success: #19beaa;
  --analog-support-success-light: #b2f5ed;
  --analog-support-warning: #b27500;
  --analog-support-warning-light: #ffe5b2;
  --analog-support-info: #1a91ff;
  --analog-support-info-light: rgba(178, 218, 255, 1);

  --analog-support-notification-border: #f3f3f6;
  --analog-support-notification-text: #f3f3f6;
  --analog-support-notification-icon: #f3f3f6;

  --analog-active-primary: var(--analog-cyan-600);
  --analog-focus-primary: #007ab2;
  --analog-disabled1: #6297ab;
  --analog-disabled2: #3b606d;

  --analog-text-field-background1: var(--analog-gray-600);
  --analog-text-field-background2: var(--analog-gray-700);
  --analog-text-field-border1: var(--analog-gray-500);
  --analog-text-field-border2: var(--analog-gray-600);
  --analog-text-field-disabled2: var(--analog-disabled2);

  --analog-gray-900: #0e0e0f;
  --analog-gray-850: #1b1c1d;
  --analog-gray-800: #27292b;
  --analog-gray-750: #333638;
  --analog-gray-700: #3e4246;
  --analog-gray-600: #555b62;
  --analog-gray-500: #6c757f;
  --analog-gray-400: #858e99;
  --analog-gray-300: #a0a8b1;
  --analog-gray-200: #bbc1c9;
  --analog-gray-100: #d7dbe0;
  --analog-gray-50: #f3f4f6;

  --analog-cyan-900: #07a0da;
  --analog-cyan-800: #0ab3f0;
  --analog-cyan-700: #25bbf4;
  --analog-cyan-600: #44c3f3;
  --analog-cyan-500: #62cdf3;
  --analog-cyan-400: #80d5f4;
  --analog-cyan-300: #9bdff8;
  --analog-cyan-200: #b9e8f9;
  --analog-cyan-100: #d7f1fb;
  --analog-cyan-50: #e4f5fb;
  --analog-cyan-25: #f1fafd;

  --analog-blue-900: #003d66;
  --analog-blue-800: #014f83;
  --analog-blue-700: #0361a0;
  --analog-blue-600: #0673bc;
  --analog-blue-500: #0985d7;
  --analog-blue-400: #0d96f2;
  --analog-blue-300: #2ca3f2;
  --analog-blue-200: #4baff1;
  --analog-blue-100: #69bbf2;
  --analog-blue-50: #86c8f3;
  --analog-blue-25: #a2d4f6;

  --analog-input-height-s: 32px;
  --analog-input-height-m: 38px;
  --analog-input-height-l: 42px;
}
