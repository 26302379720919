@import "src/styles/variables";
@import "src/styles/mixins";

.container {
  background-color: $menu-color;
  padding: 16px 16px 0;
  height: 462px;
  border-radius: 12px;

  @include tablet {
    height: 460px;
  }

  @include tablet-portrait {
    height: 100%;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectionVariables {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  flex-grow: 1;
  @include tablet-portrait {
    width: 100%;
    flex-direction: row;
  }
}

.powerTypeSelector {
  z-index: 11;

  @include tablet-portrait {
    width: 100%;
  }
}

.genericOneSelector {
  z-index: 10;
  @include tablet-portrait {
    width: 100%;
  }
}

.genericTwoSelector {
  z-index: 9;
  @include tablet-portrait {
    width: 100%;
  }
}

.selectDropDown {
  padding: 10px;
  background: transparent;
  min-height: 40px;
  border: 1px solid $card-color;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: none;
  font-size: 14px;
  color: $secondary-text-color;
  width: 100%;
}

.selectDropDown:focus {
  outline: none;
}

.containerCyclist {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include tablet-portrait {
    height: 479px;
  }
}

.wrapper {
  display: flex;
  align-items: center;

  @include tablet-portrait {
    flex-direction: column;
  }
}

.header {
  display: flex;
  align-items: left;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba($accent-primary, 0.1);
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: auto;

  @include tablet {
    font-size: 18px;
    line-height: 22px;
  }

  @include tablet-portrait {
    margin-right: auto;
    margin-left: 0;
    width: 50%;
  }
}

.stub {
  width: 50%;
}

.comparisonSelector {
  display: flex;
  background: #2a3344;
  border-radius: 100px;
  height: 28px;
  padding: 4px;
  margin-right: 16px;
}

.selectorButton {
  @include reset-button;
  background: transparent;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  border-radius: 100px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8c93a6;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
}

.selectorButtonActive {
  background: #6938ef;
  font-weight: 600;
  color: $card-color;
  letter-spacing: 1.2px;
}

.chartWrapper {
  width: calc(100% - #{$ranking-width});
  height: 373px;
  margin-top: 10px;
}

.chartWrapperCyclist {
  width: 100%;
  height: 311px;
  margin-top: 38px;
  @include tablet {
    margin-top: 38px;
    height: 335px;
  }
  @include tablet-portrait {
    height: 314px;
  }
}

.legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 27px;
  padding: 0 12px;
  margin: 0 -16px;
  background: $card-color;
  border-top: 1px solid #2e3748;
  border-radius: 0 0 12px 12px;
}

.legendItems {
  display: flex;
  align-items: center;
}

.legendItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 17px;
  margin-left: 15px;
}

.legendLine {
  width: 38px;
  border-top: 1px solid;
  margin-right: 10px;
}

.cyclistMargin {
  margin-left: 10px;
}

.bigChartSource {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 500;
  position: absolute;
  top: -10px;
  left: -10px;
}
