@import "src/styles/variables";
@import "src/styles/mixins";

$input-height: 40px;

.wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.flex {
  flex: 1;
}

.toggle {
  @include reset-button;
  display: flex;
  align-items: center;
  height: $input-height;
  padding: 9px 14px;
  border: 1px solid $card-color;
  border-radius: 6px;
  color: $secondary-text-color;
  font-size: 14px;
}

.calendarIcon {
  margin-right: 9px;
}

.calendarWrapper {
  position: absolute;
  margin-top: 4px;
  z-index: 15;
  background: $popup-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 12px;
}

.calendarWrapperRight {
  top: 0;
  right: -350px;
}

.calendarWrapperBottom {
  top: #{$input-height + 1px};
  right: 0;
}

.resetWrapper {
  margin-top: 12px;
  border-top: 1px solid #4f576d;
}

.resetButton {
  @include reset-button;
  margin-top: 12px;
  font-size: 14px;
  line-height: 48px;
  height: 48px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  background: $card-color;
  width: 100%;
  border: 1px solid $gray-bg-color;
  box-sizing: border-box;
  border-radius: 8px;
  color: #8c93a6;
  svg {
    fill: #8c93a6;
    margin-right: 15px;
  }
  @include tablet-portrait {
    justify-content: center;
  }
}
