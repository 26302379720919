@import "src/styles/variables";
@import "src/styles/mixins";

.commentIcon,
.attachmentIcon {
  margin-right: 5px;
  margin-left: 10px;
  fill: $medical-table-icon-color !important;
  cursor: pointer;
}

.count {
  margin-bottom: 2px;
  margin-right: 5px;
  position: relative;
  top: -6px;
}

.tableData {
  cursor: pointer;
}
