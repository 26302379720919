.analog-emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 auto;
}

@media screen and (max-height: 767px) {
  .analog-emptyState {
    padding: 32px;
    box-sizing: border-box;
  }
}

.analog-emptyState__inner {
  display: flex;
  flex-direction: column;
  max-width: 476px;
  text-align: center;
  align-items: center;
}

.analog-emptyState__heading {
  color: var(--analog-text-primary);
}

.analog-emptyState__heading {
  margin: 16px 0 0 0;
}

.analog-emptyState__text {
  color: var(--analog-text-secondary);
}

.analog-emptyState__text {
  margin: 4px 0 0 0;
}

.analog-emptyState__actions {
  margin: 24px 0 0 0;
}
