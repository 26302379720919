.analog-divider {
  position: relative;
  background-color: var(--analog-border-middle);
}

.analog-divider--horizontal {
  width: 100%;
  height: 1px;
}

.analog-divider--vertical {
  width: 1px;
  height: 100%;
}

.analog-divider__text {
  position: absolute;
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%, -50%);
  padding: 0 12px;
  background-color: var(--analog-surface-layer2);
  color: var(--analog-text-secondary);
}
