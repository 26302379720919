.analog-tag {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  flex-shrink: 0;
  cursor: default;
  user-select: none;
  transition: 0.15s ease-in;
}

.analog-tag--m {
  padding: 0 8px;
  height: 24px;
}

.analog-tag--s {
  padding: 0 4px;
  height: 20px;
}

.analog-tag--round.analog-tag--m {
  min-width: 24px;
}

.analog-tag--round.analog-tag--s {
  min-width: 20px;
}

.analog-tag--round {
  border-radius: 16px;
}

.analog-tag--rounded {
  border-radius: 8px;
}

.analog-tag--clickable {
  cursor: pointer;
}

.analog-tag__icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.analog-tag__remove {
  outline: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  flex-shrink: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.analog-tag--rounded .analog-tag__remove {
  border-radius: 4px;
}

.analog-tag--round .analog-tag__remove {
  border-radius: 50%;
}

.analog-tag__remove:hover,
.analog-tag__remove:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.analog-tag__text {
  padding: 0 4px;
}

.analog-tag--neutral {
  background-color: var(--analog-surface-layer2);
  border-color: var(--analog-surface-layer2);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--neutral:hover {
  background-color: var(--analog-surface-layer1);
  border-color: var(--analog-surface-layer1);
}

.analog-tag--neutral-light {
  background-color: var(--analog-surface-layer4);
  border-color: var(--analog-surface-layer4);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--neutral-light:hover {
  background-color: var(--analog-surface-layer3);
  border-color: var(--analog-surface-layer3);
}

.analog-tag--primary {
  background-color: var(--analog-interactive-primary);
  border-color: var(--analog-interactive-primary);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--primary:hover {
  background-color: var(--analog-interactive-primary-hover);
  border-color: var(--analog-interactive-primary-hover);
}

.analog-tag--primary-light {
  background-color: var(--analog-interactive-primary);
  border-color: var(--analog-interactive-primary);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--primary-light:hover {
  background-color: var(--analog-interactive-primary-hover);
  border-color: var(--analog-interactive-primary-hover);
}

.analog-tag--secondary {
  background-color: var(--analog-interactive-secondary);
  border-color: var(--analog-interactive-secondary);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--secondary:hover {
  background-color: var(--analog-interactive-secondary-hover);
  border-color: var(--analog-interactive-secondary-hover);
}

.analog-tag--secondary-light {
  background-color: var(--analog-interactive-secondary);
  border-color: var(--analog-interactive-secondary);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--secondary-light:hover {
  background-color: var(--analog-interactive-secondary-hover);
  border-color: var(--analog-interactive-secondary-hover);
}

.analog-tag--success {
  background-color: var(--analog-support-success);
  border-color: var(--analog-support-success);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--success:hover {
  background-color: var(--analog-support-success);
  border-color: var(--analog-support-success);
}

.analog-tag--success-light {
  background-color: var(--analog-support-success-light);
  border-color: var(--analog-support-success-light);
  color: var(--analog-text-inverse);
}

.analog-tag--clickable.analog-tag--success-light:hover {
  background-color: var(--analog-support-success-light);
  border-color: var(--analog-support-success-light);
}

.analog-tag--warning {
  background-color: var(--analog-support-warning);
  border-color: var(--analog-support-warning);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--warning:hover {
  background-color: var(--analog-support-warning);
  border-color: var(--analog-support-warning);
}

.analog-tag--warning-light {
  background-color: var(--analog-support-warning-light);
  border-color: var(--analog-support-warning-light);
  color: var(--analog-text-inverse);
}

.analog-tag--clickable.analog-tag--warning-light:hover {
  background-color: var(--analog-support-warning-light);
  border-color: var(--analog-support-warning-light);
}

.analog-tag--error {
  background-color: var(--analog-support-error);
  border-color: var(--analog-support-error);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--error:hover {
  background-color: var(--analog-support-error);
  border-color: var(--analog-support-error);
}

.analog-tag--error-light {
  background-color: var(--analog-support-error-light);
  border-color: var(--analog-support-error-light);
  color: var(--analog-text-inverse);
}

.analog-tag--clickable.analog-tag--error-light:hover {
  background-color: var(--analog-support-error-light);
  border-color: var(--analog-support-error-light);
}

.analog-tag--info {
  background-color: var(--analog-support-info);
  border-color: var(--analog-support-info);
  color: var(--analog-white);
}

.analog-tag--clickable.analog-tag--info:hover {
  background-color: var(--analog-support-info);
  border-color: var(--analog-support-info);
}

.analog-tag--info-light {
  background-color: var(--analog-support-info-light);
  border-color: var(--analog-support-info-light);
  color: var(--analog-text-inverse);
}

.analog-tag--clickable.analog-tag--info-light:hover {
  background-color: var(--analog-support-info-light);
  border-color: var(--analog-support-info-light);
}
