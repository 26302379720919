@import "src/styles/variables";
@import "src/styles/mixins";

.popupBox {
  position: fixed;
  z-index: 200;
  background: rgba($pop-up-bg-color, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background: $menu-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 50vw;
  padding: 32px;
  flex-direction: row;
  gap: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;

  // background-color:#008B32;
  // border-bottom: 1px solid rgba($hover-color, 0.5);
  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .closeButton {
    cursor: pointer;
    fill: $account-default-btn-color;
  }

  .notes {
    font-size: 16px;
    font-weight: bold;
  }

  .buttonContainer {
    cursor: pointer;
  }
}

.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  textarea,
  input[type="date"] {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: $sign-in-textfield-bg-color;
    color: $primary-text-color;
    height: 20rem;
  }

  textarea {
    margin-top: 10px;
    height: 170px;
    width: 100%;
    border: 0;
    font-family: "Montserrat", sans-serif, monospace;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.addButton {
  background-color: $account-button-color;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.hiddenContent {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.blockContent:hover .hidden-content {
  display: block;
}

.buttonContainer {
  position: relative;
  display: inline-block;
}

.hoverButton {
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #007bff;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
}

.hoverText {
  display: none;
  position: absolute;
  left: 15rem;
  transform: translateX(-50%);
  padding: 10px;
  background-color: #333;
  color: black;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
  background-color: white;
  padding: 10px;
  font-weight: 400;
}

.buttonContainer:hover .hoverText {
  display: block;
}

.icon {
  color: $primary-gray-400 !important;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 4px;
  left: 4px;
  fill: $primary-text-color;
}
