.sign-in__underlay {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

.sign-in__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.2);
}

.sign-in {
  position: relative;
  width: 500px;
  background-color: var(--analog-surface-canvas);
  border-radius: 8px;
  z-index: var(--layer-popover);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sign-in__header {
  box-sizing: border-box;
  width: 100%;
  padding-block-start: 16px;
  background:
    url("assets/illustrations/analog-background.svg") no-repeat left center,
    linear-gradient(
      180deg,
      rgba(35, 43, 49, 0.3) 0%,
      rgba(4, 152, 200, 0.3) 100%
    );
  background-size: 75%;
  display: flex;
  flex-direction: column;
}

.sign-in__main {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  padding: 16px;
}

.sign-in__form {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
}

.sign-in__title {
  color: var(--analog-text-primary);
}

.sign-in__form-fields {
  margin-block-start: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sign-in__extras {
  margin-block-start: 16px;
  display: flex;
  justify-content: space-between;
}

.sing-in__footer {
  margin-block-start: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 450px) {
  .sign-in__video {
    display: none;
  }

  .sign-in {
    height: 100%;
  }

  .sign-in__header {
    height: 234px;
    background-size: cover;
  }

  .sing-in__footer {
    margin-block-start: auto;
  }
}

.analog-button.ms-sign-in__button {
  background-color: var(--analog-surface-layer3);
  border-color: var(--analog-surface-layer3);
  color: var(--analog-text-primary);

  &:hover {
    background-color: var(--analog-surface-layer4);
    border-color: var(--analog-surface-layer4);
    color: var(--analog-text-primary);
  }

  &:focus-visible {
    background-color: var(--analog-surface-layer4);
    border-color: var(--analog-focus-primary);
    color: var(--analog-text-primary);
  }

  &:active {
    background-color: var(--analog-surface-layer2);
    border-color: var(--analog-surface-layer2);
    color: var(--analog-text-primary);
  }
}
