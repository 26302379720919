.analog-truncated-text {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.analog-truncated-text .analog-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.analog-truncated-text .analog-dropdown__anchor {
  height: 100%;
}

.analog-truncated-text__tooltip-anchor {
  width: 100%;
  height: 100%;
}
