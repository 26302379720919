.panel {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  min-height: 0;
  margin-block-end: 16px;
}

.panelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.panelHeaderLeft {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.panelHeaderRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  color: var(--analog-text-placeholder);
}

.totalScoreValue {
  font-size: 38px;
  font-weight: 700;
  color: var(--analog-text-primary);
  align-self: baseline;
}

.totalScoreMax {
  font-size: 14px;
  font-weight: 400;
  color: var(--analog-text-primary);
  align-self: baseline;
}

.stats {
  margin-block-start: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
