.cycling-logo-uae {
  position: relative;
  flex: 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cycling-logo-uae__image {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}

.cycling-logo-uae__details {
  display: flex;
  flex-direction: column;
  color: var(--analog-text-primary);
}

.cycling-logo-uae__underline {
  width: 100%;
  margin-block-start: 16px;
  height: 12px;
  background: #008b32;
}
