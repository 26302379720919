.hrv-rhr-feedback__toggle {
  height: 6px;
  width: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}

.hrv-rhr-feedback__toggle .analog-dropdown__anchor {
  height: 100%;
}

.hrv-rhr-feedback__toggle-bar {
  height: 100%;
}

.hrv-rhr-feedback__content {
  color: var(--analog-text-primary);
}
