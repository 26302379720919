@import "src/styles/variables";
@import "src/styles/mixins";

.charts {
  $margin: 20px;
  width: calc(100% - #{$side-tabs-width + $margin});
  margin-left: $margin;

  @include tablet-portrait {
    $margin-tablet: 15px;
    width: calc(100% - #{$side-tabs-width-tablet + $margin-tablet});
    margin-left: $margin-tablet;
  }
}

.component + .component {
  margin-top: 32px;
}

.healthTab {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.rightTab {
  // display: flex;
  padding: 0px 0px 0px 16px;
}

.healthNote {
  background-color: $menu-color;
  // padding: 16px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.health {
  // height: 333px;
  // width: 250px;
  background-color: $card-color;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.healthAttachment {
  // width: 250px;
  background-color: $menu-color;
  // padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}
