.auth__underlay {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.auth__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.2);
}

.auth {
  position: relative;
  width: 500px;
  background-color: var(--analog-surface-canvas);
  border-radius: 8px;
  z-index: var(--layer-popover);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.auth__header {
  box-sizing: border-box;
  width: 100%;
  padding-block-start: 16px;
  background:
    url("assets/illustrations/analog-background.svg") no-repeat left center,
    linear-gradient(
      180deg,
      rgba(35, 43, 49, 0.3) 0%,
      rgba(4, 152, 200, 0.3) 100%
    );
  background-size: 75%;
  display: flex;
  flex-direction: column;
}

.auth__main {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  padding: 16px;
}

@media (max-width: 1024px) {
  .auth__video {
    display: none;
  }
}

@media (max-width: 450px) {
  .auth {
    height: 100%;
    border-radius: 0;
  }

  .auth__header {
    height: 234px;
    background-size: cover;
  }
}
