@import "src/styles/variables";
@import "src/styles/mixins";

.chatBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-height: 350px;
  min-height: 240px;
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 12px;
  background: $chat-body-bg;
}

.chatBodyMaximize {
  max-height: 530px;
}

.chatBubble {
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 0.75rem;
}

.chatBubbleUser {
  justify-content: flex-end;
}

.systemImage {
  background-image: "../../../../images/chat-logo.png";
  min-height: 16px;
  min-width: 16px;
}

.loader {
  width: 16px;
  aspect-ratio: 2;
  --loader-color: #000; // Default color
  --_g: no-repeat
    radial-gradient(circle closest-side, var(--loader-color) 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }

  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }

  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }

  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}

.thumbicons {
  background-color: $chat-body-icon-bg;
  position: absolute;
  left: 10%;
  bottom: -12px;
  border-radius: 10px;
  height: 20px;
  width: 50px;
  z-index: 99;
}

.like {
  width: 25px;
  padding-top: 2px;
  cursor: pointer;
  transition: fill 0.3s ease;
  stroke: $chat-feedback-btn-stroke-color;
  stroke-width: 1.5px;
}

.liked {
  fill: $chat-feedback-btn-fill-color;
  width: 25px;
  stroke: none;
}

.dislike {
  width: 25px;
  padding-top: 2px;
  stroke: $chat-feedback-btn-stroke-color;
  stroke-width: 1.5px;
}

.disliked {
  fill: $chat-feedback-btn-fill-color;
  width: 25px;
  stroke: none;
}

.feedbackWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  font-size: 10px;
  font-weight: 600;
  margin: 5px 0px 0px 17px;
  cursor: pointer;
}

.feedbackOption {
  text-wrap: nowrap;
  border-radius: 10px;
  color: $accent-primary;
  border: 1px solid $accent-primary;
  margin: 2px;
  padding: 5px;
}

.feedbackSuccess {
  text-wrap: nowrap;
  border-radius: 10px;
  color: $chat-feedback-color;
  border: 1px solid $chat-feedback-color;
  margin: 2px;
  padding: 5px;
  align-self: center;
  font-size: 10px;
  font-weight: 600;
}

.selectedOption {
  color: white;
  background-color: $accent-primary;
}

// .feedbackOption:hover{
//   color:white;
//   background-color: $accent-primary;
// }

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
