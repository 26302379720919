.analog-switch {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

.analog-switch--reverse {
  flex-direction: row-reverse;
}

.analog-switch__toggle {
  position: relative;
  box-sizing: border-box;
  border-radius: 32px;
  transition: background-color ease-in 0.12s;
  background-color: var(--analog-surface-layer2);
}

.analog-switch:focus .analog-switch__toggle,
.analog-switch:hover .analog-switch__toggle {
  background-color: var(--analog-surface-layer3);
}

.analog-switch__toggle-inner {
  position: absolute;
  top: 2px;
  inset-inline-start: 2px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analog-switch__toggle-inner:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  background-color: var(--analog-white);
  transition: all 0.2s;
}

.analog-switch:active .analog-switch__toggle-inner:before {
  inset-inline-start: 0;
  inset-inline-end: -20%;
}

.analog-switch--primary.analog-switch--checked .analog-switch__toggle {
  background-color: var(--analog-interactive-primary);
}

.analog-switch--primary.analog-switch--checked:focus .analog-switch__toggle,
.analog-switch--primary.analog-switch--checked:hover .analog-switch__toggle {
  background-color: var(--analog-interactive-primary-hover);
}

.analog-switch--success.analog-switch--checked .analog-switch__toggle {
  background-color: var(--analog-support-success);
}

.analog-switch--success.analog-switch--checked:focus .analog-switch__toggle,
.analog-switch--success.analog-switch--checked:hover .analog-switch__toggle {
  background-color: var(--analog-support-success);
}

.analog-switch--checked.analog-switch--loading:focus,
.analog-switch--checked:active .analog-switch__toggle-inner:before {
  inset-inline-start: -20%;
  inset-inline-end: 0;
}

.analog-switch__text {
  color: var(--analog-text-primary);
  font-weight: 400;
}

.analog-switch:disabled {
  pointer-events: none;
}

.analog-switch:disabled .analog-switch__toggle {
  background-color: var(--analog-disabled2);
}

.analog-switch:disabled .analog-switch__text {
  color: var(--analog-text-field-disabled2);
}

.analog-switch--xs {
  gap: 4px;
}

.analog-switch--xs .analog-switch__toggle {
  width: 26px;
  height: 17px;
}

.analog-switch--xs .analog-switch__toggle-inner {
  width: 13px;
  height: 13px;
  border-radius: 13px;
}

.analog-switch--xs .analog-switch__toggle-inner:before {
  border-radius: 6px;
}

.analog-switch--xs .analog-switch__text {
  line-height: 16px;
}

.analog-switch--xs.analog-switch--checked .analog-switch__toggle-inner {
  inset-inline-start: calc(100% - 15px);
}

.analog-switch--xs .analog-spinner {
  width: 11px;
  height: 11px;
}

.analog-switch--s {
  gap: 8px;
}

.analog-switch--s .analog-switch__toggle {
  width: 32px;
  height: 20px;
}

.analog-switch--s .analog-switch__toggle-inner {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}

.analog-switch--s .analog-switch__toggle-inner:before {
  border-radius: 8px;
}

.analog-switch--s .analog-switch__text {
  line-height: 17px;
}

.analog-switch--s.analog-switch--checked .analog-switch__toggle-inner {
  inset-inline-start: calc(100% - 18px);
}

.analog-switch--m {
  gap: 8px;
}

.analog-switch--m .analog-switch__toggle {
  width: 38px;
  height: 24px;
}

.analog-switch--m .analog-switch__toggle-inner {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.analog-switch--m .analog-switch__toggle-inner:before {
  border-radius: 10px;
}

.analog-switch--m .analog-switch__text {
  line-height: 21px;
  font-weight: 600;
}

.analog-switch--m.analog-switch--checked .analog-switch__toggle-inner {
  inset-inline-start: calc(100% - 22px);
}
