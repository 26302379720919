@import "src/styles/mixins";

.wrapper {
  margin-left: 0;
  margin-right: auto;
  display: flex;
}

.athleteHeader {
  display: flex;
  align-items: center;
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  text-align: center;
}

.image {
  max-width: 64px;
  max-height: 64px;
}

.info {
  margin-left: 16px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;

  @include tablet {
    font-size: 16px;
  }
}

.strong {
  font-weight: 600;
}

.age {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.country {
  display: flex;
  align-items: center;
  height: 25px;
  margin-left: 20px;
  border-radius: 5px;
}
