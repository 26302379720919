@import "src/styles/variables";
@import "src/styles/mixins";

.date-group-by-selector {
  .group-by-selector__select {
    width: 200px;
    z-index: 9;
  }

  .group-by-selector__select .react-select {
    &__control {
      background: transparent;
      min-height: 40px;
      border: 1px solid $card-color;
      border-radius: 6px;
      cursor: pointer;
      box-shadow: none;
      font-size: 14px;

      &:hover {
        border-color: $card-color;
      }
    }

    &__single-value {
      color: $secondary-text-color;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      color: $secondary-text-color;
    }

    &__menu {
      background: $popup-color;
      color: $secondary-text-color;
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
      margin: 0;
      padding: 0;
    }

    &__option {
      cursor: pointer;
      font-size: 14px;
      border-radius: 5px;

      &:hover,
      &:focus,
      &--is-focused {
        background-color: $hover-color;
      }

      &--is-selected,
      &--is-selected:hover {
        background-color: $accent-primary;
      }
    }
  }

  .group-by-selector__select_predication {
    width: 30vw;

    .react-select__control {
      padding-left: 0;
    }
  }

  .group-by-selector__select--small {
    width: 142px;
  }

  .group-by-selector__select--small .react-select {
    &__control {
      border: 1px solid #333b4a;
      padding-left: unset;
    }
  }
}

.group-by-selector__select {
  width: 100%;
  border: 1px solid #ccc;
  // background-color: #353943;
  color: $dropdown-color;
  border-radius: 6px;
}

.physiotherapy-popup_selectBox__\+1ilw,
.physiotherapy-popup_selectBox__\+1ilw,
.illness-popup_selectBox__Mf8by,
.injury-popup_selectBox__pdkZc {
  .react-select__control {
    background: $popup-bg-dropdown;
    border-radius: 10px !important;
    color: #bbb !important;
    min-height: 40px !important;
    width: 100% !important;
    border-color: $card-color;
  }

  .react-select__control:focus {
    border-color: $accent-primary !important;
    box-shadow: 0 0 0 1px $accent-primary !important;
  }

  .react-select__single-value {
    color: $dropdown-selected-value-color !important;
    font-size: 14px;
    font-weight: 550;
  }

  .react-select__indicator-separator {
    display: none !important;
  }

  .react-select__menu {
    border-radius: 4px !important;
    font-size: 14px;
    background-color: $menu-color;
    color: $popup-drop-items-color;
  }

  .react-select__option {
    /* Background color for options */
    color: $react-select-option-color !important;
    font-weight: 500;
  }

  .react-select__placeholder {
    font-size: 14px;
    color: $secondary-text-color;
  }

  .react-select__input {
    color: $primary-text-color;
  }

  // .react-select__multi-value {
  //   width: 25vw !important;
  // }

  .css-1hwfws3 {
    width: 25vw !important;
  }

  .react-select__option--is-selected {
    background-color: $calendar-bg-color !important;
    color: $react-select-selected-text-color !important;
    font-weight: 500;
  }

  .react-select__option--is-focused {
    background-color: $calendar-bg-color !important;
    color: $react-select-selected-text-color !important;
    font-weight: 500;
  }
}

// .datePickerContainer{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// }

.customDatePicker {
  background: $popup-color;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-family: inherit;
  outline: none;
  cursor: pointer;
}

.flatpickr-calendar {
  background-color: $react-select-surface-color !important;
  color: $primary-text-color !important;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.flatpickr-months {
  background-color: #2f2f2f;
}

.flatpickr-month {
  color: $primary-text-color !important;
}

.flatpickr-weekdays {
  background: $react-select-surface-color !important;

  span.flatpickr-weekday {
    background: $react-select-surface-color !important;
    color: $primary-text-color !important;
  }
}

.flatpickr-day {
  color: $primary-text-color !important;
  border-radius: 5px;
}

.flatpickr-months .flatpickr-prev-month svg {
  fill: $primary-text-color !important;
}

.flatpickr-months .flatpickr-next-month svg {
  fill: $primary-text-color !important;
}

.flatpickr-day.today {
  background-color: $react-select-surface-color !important;
  color: $primary-text-color !important;
}

.flatpickr-day.selected {
  background-color: $accent-primary !important;
  color: $primary-text-color !important;
}

.flatpickr-day:hover {
  background-color: $accent-primary !important;
}

.flatpickr-days {
  background-color: $react-select-surface-color !important;
}

.flatpickr-months .flatpickr-month {
  background: $react-select-surface-color !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $react-select-surface-color !important;
  font-weight: 600 !important;
}

.flatpickr-months {
  border-radius: 10px 10px 0 0;
}

.flatpickr-innerContainer {
  border-radius: 0 0 10px 10px;
  background-color: $react-select-surface-color !important;
}

.physiotherapy-popup_customDatePicker__3z6Yw {
  background: none;
  border: 1px solid #008b32;
  border-radius: 10px;
  color: #008b32;
}

.flatpickr-day.selected,
.flatpickr-day.startRange {
  background-color: $accent-primary !important;
  border: none !important;
  color: #fff !important;
}

.flatpickr-current-month {
  font-size: 105% !important;
}

.flatpickr-input {
  color: $dropdown-selected-value-color !important;
  font-weight: 500 !important;
}

.flatpickr-day:hover {
  background-color: $accent-primary !important;
  border: none !important;
  color: #fff !important;
}

// multi-select chips
.css-1rhbuit-multiValue {
  background-color: $accent-primary !important;
  border-radius: 10px !important;
}

// multiselect label
.css-12jo7m5 {
  color: $react-select-focus-color !important;
  font-size: 12px !important;
}
