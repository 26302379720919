@import "src/styles/mixins";

.topContainer {
  margin-bottom: 32px;
}

.topContainerCyclist {
  gap: 16px;
  flex-direction: row;
  display: flex;
  @include tablet-portrait {
    flex-direction: column;
  }
  @include tablet {
    flex-direction: column;
  }
}

.topHeaderCls {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.hidden {
  display: none;
}
