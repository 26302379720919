body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button {
  font-family: inherit;
  letter-spacing: inherit;
}

* {
  box-sizing: border-box;
}

@media (max-width: 768px) {
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    height: 100dvh;
    background-color: var(--analog-surface-canvas);
  }
}
