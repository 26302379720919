@import "src/styles/mixins";
@import "src/styles/variables";

.wrapper {
  height: 90%;
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 20px;
}

.container {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.leftPanel {
  padding: 20px 20px 0 30px;
  width: 30%;
  height: 100%;
  background-color: $athlete-card-bg-color;
  border-radius: 10px;

  .groupInfo {
    .title {
      font-size: 30px;
      font-weight: bold;

      @include tablet {
        font-size: 20px;
      }
    }
  }

  .groupForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .formGroup {
      display: flex;
      flex-direction: column;

      .label {
        font-size: 20px;
        margin-bottom: 10px;

        @include tablet {
          font-size: 16px;
        }
      }

      input {
        height: 40px;
        border-radius: 5px;
        border: 1px solid $input-border-color;
        padding: 0 10px;
        color: $primary-text-color;
        font-size: 16px;
        background-color: $background-text-field;

        @include tablet {
          font-size: 12px;
        }
      }

      textarea {
        resize: none;
        height: 300px;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 16px;
        font-family: inherit;
        color: $primary-text-color;
        border: 1px solid $input-border-color;
        background-color: $background-text-field;

        @include tablet {
          height: 200px;
          font-size: 12px;
        }
      }

      ::placeholder {
        color: $gray-bg-color;
      }
    }

    .assistiveText {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include tablet {
        font-size: 12px;
      }
    }
  }
}

.rightPanel {
  width: 70%;
  height: 100%;
  background-color: $athlete-card-bg-color;
  border-radius: 10px;
  padding: 20px 20px 0 30px;
  overflow-y: auto;

  .athleteSearch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    h1 {
      font-size: 30px;
      font-weight: bold;

      @include tablet {
        font-size: 20px;
      }
    }
  }

  .item {
    display: grid;
    grid-template-columns: auto auto 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
    align-items: center;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $bottom-border-color;
    gap: 12px;

    :hover {
      cursor: pointer;
    }
  }

  .itemDisabled {
    opacity: 0.4;
  }

  .checkbox {
    background: none;
    border: none;
    height: fit-content;
  }

  .itemImage {
    height: 62px;
    width: 52px;
    margin-left: 12px;

    @include tablet {
      height: 42px;
      width: 32px;
    }
  }

  .itemContent {
    display: flex;
    flex-direction: row;
    margin-left: 8px;
  }

  .itemName {
    font-weight: 600;
    font-size: 20px;

    @include tablet {
      font-size: 16px;
    }
  }

  .itemNationalContainer {
    height: 20px;
    width: 20px;
  }
  .itemFlag {
    height: 30px;
    width: 30px;
    border-radius: 5px;

    @include tablet {
      height: 20px;
      width: 20px;
    }
  }

  .athleteInfo {
    @include tablet {
      font-size: 12px;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  .saveButton {
    width: 200px;
    border: 1px solid $accent-primary;
  }

  .cancelButton {
    width: 200px;
    border: 1px solid $accent-secondary;
    background-color: $accent-secondary;
  }
}
