@import "src/styles/variables";

.button {
  display: inline-block;
  padding: 12px 0;
  width: 100px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-left: 15px;
  border: none;
  background: $account-button-color;
  color: white;
  pointer-events: all;
}

.button:disabled {
  background: #8e99b9;
}
