.analog-menu {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
}

.analog-menu__content {
  background-color: var(--analog-surface-layer2);
  color: var(--analog-text-primary);
  border-radius: 8px;
  padding: 8px 0;
  z-index: var(--layer-overlay);
  outline: none;
  overflow-y: auto;
}

.analog-menu__option-wrapper {
  padding: 0 4px;
}

.analog-menu__option {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  outline: none;
  gap: 8px;
  border: 0;
  border-radius: 8px;
  text-align: left;
  padding: 8px 16px;
  color: var(--analog-text-primary);
  background-color: var(--analog-surface-layer2);
  cursor: pointer;
  transition: 0.075s ease-out;
}

.analog-menu__option:hover,
.analog-menu__option:focus-visible,
.analog-menu__option--active {
  background-color: var(--analog-surface-layer4);
}

.analog-menu__option:active {
  background-color: var(--analog-surface-layer3);
}

.analog-menu__option-startAdornment {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--analog-text-primary);
}

.analog-menu__option-endAdornment {
  margin-inline-start: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--analog-text-primary);
}

.analog-menu__divider {
  height: 1px;
  margin: 4px 0;
  width: 100%;
  background-color: var(--analog-surface-layer4);
  pointer-events: none;
}

/* Nested */
.analog-menu__option-dropdown {
  width: 100%;
}

.analog-menu__content .analog-dropdown__anchor {
  width: 100%;
}
