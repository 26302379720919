@import "src/styles/variables";
@import "src/styles/mixins";

.alertIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.heyKevin {
  font-weight: 600;
}
.justAReminder {
  font-weight: 500;
}
.settings {
  position: relative;
  font-size: 14px;
  line-height: 150%;
}

.systemalertLineswapParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: $sign-in-text-color;
  gap: 8px;
}

.topWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 12px;
  background-color: $warning;
  margin-bottom: 8px;
  border-radius: 8px;
}

.reportButton {
  min-width: 127px;
  font-size: 12px;
  line-height: 150%;
  color: $submitAllButtonColor;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 28px;
  font-weight: 500;
}
