@import "src/styles/variables";
@import "src/styles/mixins";

.atheteCard {
  flex: 1;
  height: 355px;
  width: 100%;
  min-width: 262px;
  max-width: 330px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: $card-background-color;
  border-radius: 7px;
  cursor: pointer;

  .athleteBottomStrip {
    height: 10px;
    width: 100%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    z-index: 10;
    position: relative;
    cursor: default;

    &:hover::after {
      content: attr(data-tooltip);
      justify-content: normal;
      position: absolute;
      width: 255px;
      top: -100px;
      left: 50%;
      gap: 8px;
      transform: translateX(-50%);
      background-color: #f3f3f6;
      color: #13171d;
      border-radius: 4px;
      white-space: normal;
      font-size: 9px;
      font-weight: 400;
      padding: 4px 12px 4px 12px;
      line-height: 1.5;
      box-shadow: 0px 1px 2px 0px #13171d4d;
      z-index: 20;
      pointer-events: none;

      &::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 8px;
        border-style: solid;
        border-color: #f3f3f6 transparent transparent transparent;
        z-index: 21;
      }
    }
  }

  .athleteMain {
    height: 50%;

    .athleteImage {
      background-color: #c4c4c4;
      border-radius: 9px;
      position: relative;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 17%;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(
          transparent,
          rgba($card-background-color, 0.01) 0%,
          rgba($card-background-color, 0.05) 5%,
          rgba($card-background-color, 0.08) 10%,
          rgba($card-background-color, 0.13) 12%,
          rgba($card-background-color, 0.15) 17%,
          rgba($card-background-color, 0.3) 30%,
          rgba($card-background-color, 1) 60%
        );
      }

      .athleteName {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: $primary-card-text-color;
        margin: 2px 10px;
        text-align: left;
        z-index: 1;
        font-size: 16px;
      }

      .athleteFlag {
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 36px;

        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $card-corner-color;
        border-top-left-radius: 7px;
        border-bottom-right-radius: 7px;

        img {
          height: 50%;
          width: 50%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .athleteOptions {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 36px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $card-corner-color;
        border-top-right-radius: 7px;
        border-bottom-left-radius: 7px;

        svg {
          fill: $card-vertical-dots-color;
        }
      }
    }
  }

  .athleteDetails {
    font-size: 9px;
    height: 10%;
    margin: 2px 10px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    color: $primary-card-text-color;

    .groupName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .athleteAdditionalDetails {
      display: flex;
      flex-direction: row;
      gap: 3px;

      .xAvatarComponents3 {
        display: flex;
        gap: 5px;

        &:not(:last-child)::after {
          content: "|";
        }
      }
    }

    .activeMedicalReportIcon {
      width: 1rem;
      font-size: larger;
    }
  }

  .athleteMisc {
    height: 40%;
    margin: 2px 10px 10px 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: $card-details-bg-color;

    .performanceScores {
      height: 20%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba($primary-card-text-color, 0.2);

      .performanceScore {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: $card-item-value;

        .performanceScoreTitle {
          font-size: 10px;
          color: $athlete-card-info-color;
        }

        .performanceScoreValue {
          font-size: 12px;
          font-weight: 500;
          color: $detail-value-color;
        }
      }
    }

    .relativeScores {
      padding: 10px 0;
      height: 60%;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgba($primary-card-text-color, 0.2);

      .leftSection {
        flex: 2;
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgba($primary-card-text-color, 0.2);
      }

      .rightSection {
        flex: 1.1;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        align-items: flex-end;
        text-align: right;
      }

      .relativeScoreTitle {
        flex: 1;
        font-size: 10px;
        color: $athlete-card-info-color;
      }

      .relativeScoreValues {
        flex: 2;
        display: flex;
        font-size: 12px;
        color: $card-item-value;
        align-items: center;
        gap: 5px;
      }
    }

    .heartRateScores {
      padding-top: 10px;
      height: 20%;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .heartRateScore {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: $card-item-value;

        .heartRateScoreTitle {
          font-size: 10px;
          color: $card-item-title;
        }

        .heartRateScoreValue {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

.units {
  color: $card-item-unit;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

.unitsValue {
  font-size: 12px;
  line-height: 150%;
  font-weight: 500;
  justify-content: center;
  display: flex;
  gap: 1px;
}

.dropdownMenu {
  display: block;
  text-align: left;
}

.expandDown {
  fill: $accent-primary;
}

.dropdown {
  margin-top: 0px;
  padding: 0 10px;
  border-radius: 10px;
  display: block;
  position: absolute;
  background-color: $dropdown-bg;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
  right: 0;
  top: 100%;
  opacity: 1;
  animation: dropDownFadeIn 0.3s ease-in-out;
}

.button {
  @include reset-button;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 16px;
  padding: 10px 20px;
  border: 1px solid $accent-primary;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $accent-primary;
  background: $account-btn-bg;

  & svg {
    height: 20px;
    width: 20px;
    fill: $accent-primary;
  }
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  border: 1px solid red;
}

@keyframes dropDownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
