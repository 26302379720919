.cycling-logo-adq {
  position: relative;
  flex: 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cycling-logo-adq__image {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}

.cycling-logo-adq__underline {
  width: 100%;
  margin-block-start: 16px;
  height: 12px;
  background: linear-gradient(
    98.88deg,
    #4d8fd7 10.98%,
    #e56765 59.94%,
    #f7ca70 109.8%
  );
}
