.forgot-password__title {
  color: var(--analog-text-primary);
}

.forgot-password__form {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
}

.forgot-password__form-fields {
  margin-block-start: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forgot-password__footer {
  margin-block-start: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.forgot-password--sent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 auto;
}

@media (max-width: 450px) {
  .forgot-password__footer {
    margin-block-start: auto;
  }
}
