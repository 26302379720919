@import "src/styles/variables";
@import "src/styles/mixins";

.chatSuggestion {
  padding: 12px 0;
  display: inline-block;
  margin-left: 0;
  display: flex;
  border: none;
  border-radius: 8px;
  background-color: $chat-suggestion-bg;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 150%;
  font-weight: 500;
  padding: 3px 12px;
  color: $chat-suggestion-color;
  cursor: pointer;
}

.disabled {
  cursor: none;
  cursor: not-allowed;
  opacity: 0.3; // Set overall transparency to 0.3
  pointer-events: none; // Ensure it's non-interactive when disabled
}
