@import "src/styles/variables";
@import "src/styles/mixins";

.popupBox {
  position: fixed;
  z-index: 200;
  background: rgba($pop-up-bg-color, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  background: $menu-color;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 50vw;
  padding: 32px;
  flex-direction: row;
  gap: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  // background-color:#008B32;
  // border-bottom: 1px solid rgba($hover-color, 0.5);

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .closeButton {
    cursor: pointer;
    fill: $accent-primary;
  }
  .notes {
    font-size: 16px;
    font-weight: bold;
  }
}

.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  textarea,
  input[type="date"] {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #353943;
    color: #fff;
  }

  textarea {
    height: 100px;
    width: 100%;
  }
}
