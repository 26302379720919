.analog-formElement {
  display: flex;
  flex-direction: column;
}

.analog-formElement__label {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-block-end: 12px;
}

.analog-formElement__labelText {
  color: var(--analog-text-primary);
}

.analog-formElement__requiredMark {
  color: var(--analog-support-error);
}

.analog-formElement__tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--analog-text-primary);
  cursor: pointer;
}

.analog-formElement__skeleton {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.analog-formElement__adornment {
  margin-inline-start: auto;
}

.analog-formElement__message {
  margin-block-start: 4px;
}
