.formSlider {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: var(--analog-surface-layer1);
}

.question {
  color: var(--analog-text-primary);
}

.boundaries {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boundary {
  color: var(--analog-text-secondary);
}
