.analog-segment {
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 4px;
  border-radius: 20px;
  gap: 4px;
  background-color: var(--analog-surface-layer1);
}

.analog-segment__button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 32px;
  gap: 8px;
  position: relative;
  z-index: 1;
  padding: 0 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-out;
  outline: none;
  user-select: none;
  background-color: transparent;
  color: var(--analog-text-tertiary);
}

.analog-segment__button:not(.analog-segment__button--active):hover,
.analog-segment__button:not(.analog-segment__button--active):focus {
  color: var(--analog-text-primary);
}

.analog-segment__button--active {
  color: var(--analog-text-inverse);
}

.analog-segment__slider {
  box-sizing: border-box;
  position: absolute;
  height: 32px;
  inset-block-start: 4px;
  inset-inline-start: 4px;
  background-color: var(--analog-surface-layer-inverse);
  border: 1px solid var(--analog-surface-layer-inverse);
  border-radius: 16px;
  transition: 0.3s;
  z-index: 0;
}
