.analog-file-uploader {
  width: 100%;
  cursor: default;
}

.analog-file-uploader__input {
  display: none;
}

.analog-file-uploader__dropzone {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 2px dashed var(--analog-interactive-primary);
  background-color: var(--analog-surface-layer2);
  border-radius: 8px;
  padding: 34px;
  text-align: center;
}

.analog-file-uploader__button {
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  color: var(--analog-link-primary);
  cursor: pointer;
  user-select: none;
}

.analog-file-uploader__button:focus-visible {
  text-decoration: underline;
}

.analog-file-uploader__icon {
  color: var(--analog-icon-primary);
}

.analog-file-uploader__files {
  margin-block-start: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Attachments */

.analog-attachment {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.analog-attachment__content {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  gap: 12px;
  background-color: var(--analog-surface-layer1);
  border: 2px solid var(--analog-surface-layer1);
}

.analog-attachment--invalid .analog-attachment__content {
  border-color: var(--analog-support-error);
}

.analog-attachment__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--analog-surface-layer2);
  color: var(--analog-text-secondary);
}

.analog-attachment__name {
  color: var(--analog-text-primary);
}

.analog-attachment__size {
  color: var(--analog-text-secondary);
}

.analog-attachment__actions {
  margin-inline-start: auto;
  display: flex;
  align-items: center;
}
