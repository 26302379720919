.header {
  position: relative;
}

.headerImage {
  height: 0;
  padding-top: 51.84%;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.headerTransition {
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(27, 33, 44, 0) 2.16%,
    #0e0e0f 78.04%
  );
  z-index: 1;
  height: 15.6%;
  width: 100%;
}

.headerCountry {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  border-bottom-right-radius: 8px;
  background: #00000033;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerCountryFlag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.headerMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  border-bottom-left-radius: 8px;
  background: #00000033;
  color: var(--analog-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
