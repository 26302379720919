@import "src/styles/mixins";
@import "src/styles/variables";

.wrapper {
  margin-left: 0;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.dropdownMenu {
  display: block;
  text-align: left;
}

.expandDown {
  fill: $accent-primary;
}

.dropdown {
  margin-top: 10px;
  padding: 0 10px;
  border-radius: 10px;
  display: block;
  position: absolute;
  background-color: $dropdown-bg;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 11;
  right: 0;
  top: 100%;
  opacity: 1;
  animation: dropDownFadeIn 0.3s ease-in-out;
}

.button {
  @include reset-button;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 16px;
  padding: 10px 20px;
  border: 1px solid $accent-primary;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $accent-primary;
  background: $account-btn-bg;

  & svg {
    height: 20px;
    width: 20px;
    fill: $accent-primary;
  }
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  border: 1px solid red;
}

@keyframes dropDownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
