.form {
  height: 100dvh;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--analog-surface-canvas);
  overflow-y: auto;
  z-index: 1;
  animation: slideInFromBottom 0.5s ease-out forwards;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.formHeader {
  padding: 16px 16px 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--analog-text-primary);
}

.formFooter {
  margin-block-start: 16px;
  padding: 16px 16px 32px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: var(--analog-surface-layer1);
}
