@import "src/styles/variables";
@import "src/styles/mixins";

.content {
  height: 400px;
  background-color: $chart-bg-color;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}
