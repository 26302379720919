.stats {
  padding: 10px 15px 0;
  display: flex;
  flex-direction: column;
}

.statsCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-block-start: 8px;
  background-color: var(--analog-surface-layer1);
  padding: 6px 7px 6px 7px;
  border-radius: 4px;
}

.statsCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  border-bottom: 1px solid var(--analog-border-middle);
}

.cardMiddle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  border-bottom: 1px solid var(--analog-border-middle);
}

.cardMiddleDivider {
  width: 1px;
  height: 32px;
  background: #495366;
}

.cardFooter {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 4px 0;
}
