@import "src/styles/variables";
@import "src/styles/mixins";

.content {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.header {
  display: flex;
  justify-content: space-between;
}

.smallHeader {
  composes: header;
  margin-bottom: 10px;
  flex: 0 1 auto;
}

.bigHeader {
  composes: header;
  margin-bottom: 20px;
}

.alignRight {
  justify-content: right;
}

.bold {
  font-weight: bold;
  text-transform: capitalize;
}

.title {
  max-width: 50%;
}

.smallTitle {
  composes: title;
  font-size: 14px;
  @include tablet {
    font-size: 12px;
  }
}

.bigTitle {
  composes: title;
  font-size: 18px;
}

.legendItems {
  max-width: 50%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  text-align: left;
  align-items: flex-start;
}

.sourceBarLegendItems {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 0.5rem;
  text-align: left;
  align-items: flex-start;
}

.fullWidth {
  margin-left: auto;
  margin-right: 0;
  max-width: 100%;
}

.legendItem {
  margin-right: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column;
}

.sourceBarLegendItem {
  margin-right: 4px;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.oneLineLegend {
  flex-direction: row;
  gap: 1rem;
}

.chart {
  flex: 1 1;
}

.bigChartSource {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  top: -15px;
  left: -15px;
}

.smallChartSource {
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  top: -5px;
  left: -5px;
}
