.statValue {
  display: flex;
  align-items: center;
  gap: 4px;
}

.cardItemVertical {
  display: flex;
  flex-direction: column;
}

.tokenPlaceholder {
  color: var(--analog-text-placeholder);
}

.mcpLabel {
  color: var(--analog-text-secondary);
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
}

.mcpValue {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mcpValueWKGPercent {
  color: #4caf50;
}

.mcpValueWKGValue {
  color: var(--analog-text-primary);
}

.mcpValueWPercent {
  color: #42a5f5;
}

.mcpValueWValue {
  color: var(--analog-text-primary);
}

.mcpValueWUnits {
  color: var(--analog-text-placeholder);
}
